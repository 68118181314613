import { ApartmentLink } from "@app/types/checkerboard"

type separatedGroupsType = {
	singleGroups: ApartmentLink[][]
	multipleGoups: ApartmentLink[][]
}

function isMyGroup(this: ApartmentLink, group: ApartmentLink[]): boolean {
    if ( !group[0]?.apartment?.groupId || !this?.apartment?.groupId ) {
        return false
    }
    return group[0].apartment.groupId === this.apartment.groupId
}

function addApartmentLinkToGroup(this: ApartmentLink, groups: ApartmentLink[][]) {
    const itemGroupChecker = isMyGroup.bind(this)
	const group = groups.find(itemGroupChecker)
	if (group) {
		group.push(this)
	} else {
		groups.push([this])
	}
}

/**
 * Объединяет кваритры в массивы по groupId. Возвращает массив массивов
 * 
 * @param {ApartmentLink[]} apartmentLinks - массив объектов квартир с информацией о размещении в здании
 * @returns {ApartmentLink[][]} - сгруппированные квартиры по groupId
 */
export function groupingApartmentLinks(apartmentLinks: ApartmentLink[]): ApartmentLink[][] {

    const separatedGroups = apartmentLinks.reduce<separatedGroupsType>(
		(acc, item) => {
			if (item.apartment?.groupId) {
                addApartmentLinkToGroup.call(item, acc.multipleGoups)
			} else {
				acc.singleGroups.push([item])
			}

			return acc
		},
		{ multipleGoups: [], singleGroups: [] }
	)

    return [...separatedGroups.multipleGoups, ...separatedGroups.singleGroups]
}