import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";

export const removeFloorThunk = createAsyncThunk<void, number | undefined, { dispatch: AppDispatch, state: RootState }>(
    "@@floor/remove",
    async (id, { dispatch, getState }) => {
        const { floorSlice } = getState()
        const floorId = id || floorSlice.selectedId
        if (!floorId) {
			dispatch(alertComponentShow({ severity: "error", text: "Этаж не определен" }))
		}
        try {
            const text = await api.floor.delete(floorId)
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
        
    }
)

export function removeFloorPending(state: RootState["floorSlice"]) {
    state.removeInProcess = true
}

export function removeFloorRejected(state: RootState["floorSlice"]) {
    state.removeInProcess = false
}

export function removeFloorFulfilled(state:RootState["floorSlice"]) {
    state.removeInProcess = false
}