import AppApi from "@app/api/index";
import {ContactUsDto} from "@app/types/contact";
import {contact} from "@app/api/endpoints";

export default class ContactApi {
    constructor(private readonly api: AppApi) {}

    public send(dto: ContactUsDto): Promise<never> {
        return this.api.client.post<ContactUsDto, never>(contact.send(), dto)
    }
}
