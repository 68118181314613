import AppApi from "@app/api/index";
import {developer} from "@app/api/endpoints";
import {Pagination} from "@app/types/pagination";
import {
    BanDeveloperDto,
    CreateDeveloperDto,
    GetDevelopersParams,
    Developer,
    UpdateDeveloperDto
} from "@app/types/developer";

export default class DeveloperApi {
    constructor(private readonly api: AppApi) {}

    public getAll(params: GetDevelopersParams): Promise<Pagination<Developer[]>> {
        return this.api.client.get<null, Pagination<Developer[]>>(developer.getAll(), {params: params})
    }

    public getById(id: number): Promise<Developer> {
        return this.api.client.get<null, Developer>(developer.getById(id))
    }

    public create(dto: CreateDeveloperDto): Promise<Developer> {
        return this.api.client.post<CreateDeveloperDto, Developer>(developer.create(), dto)
    }

    public remove(id: number): Promise<boolean> {
        return this.api.client.delete<null, boolean>(developer.delete(id))
    }

    public update(id: number, dto: UpdateDeveloperDto): Promise<Developer> {
        return this.api.client.patch<UpdateDeveloperDto, Developer>(developer.update(id), dto)
    }

    public ban(dto: BanDeveloperDto): Promise<null> {
        return this.api.client.post<BanDeveloperDto, null>(developer.ban(), dto)
    }
}
