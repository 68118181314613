import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { GroupDtoCell } from './../../../app/types/checkerboard';
import { AppDispatch, RootState } from './../../../store/index';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateGroupDto, PlateboardStateType, PlateboardCellType, Cell, Floor, Riser } from "@app/types/checkerboard"
import { api } from "@app/api"

function getFloorRiserCell(cell: PlateboardCellType): GroupDtoCell {
    const floorId = !isNaN(+cell.floorId) ? +cell.floorId : 0
	const riserId = !isNaN(+cell.riserId) ? +cell.riserId : 0
	return { floorId, riserId }
}

export const createGroupThunk = createAsyncThunk<void, void, { dispatch: AppDispatch, state: RootState }>("@@checkerboard/create-group", async (_, thunkApi) => {
	const { dispatch, getState } = thunkApi
	const state = getState()
	const checkerboardId = state.plateboard.checkerboard?.id
	const cells = state.plateboard.selectedCells.map(getFloorRiserCell)
	const sectionId = state.plateboard.section?.id
	const dto: CreateGroupDto = { cells, sectionId }
	await api.checkerboard.createGroup(checkerboardId, dto)
	dispatch(getPlateboardThunk({ checkerboardId }))
})

export function createGroupPending(state: PlateboardStateType) {
	state.createGroupIsLoading = true
}

export function createGroupFulfilled(state: PlateboardStateType) {
	state.createGroupIsLoading = false
}

export function createGroupRejected(state: PlateboardStateType) {
	state.createGroupIsLoading = false
}