import AppApi from "./index";
import {UploadFileResponse} from "../types/file";

export default class FileApi {
    constructor(private readonly api: AppApi) {}

    public uploadImage(file: File): Promise<UploadFileResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return this.api.client.post<FormData, UploadFileResponse>('/files/uploadImage', formData)
    }

    public uploadDocument(file: File): Promise<UploadFileResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return this.api.client.post<FormData, UploadFileResponse>('/files/uploadDocument', formData)
    }
}