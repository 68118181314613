import AppApi from "@app/api/index";
import {Filter, FilterParams} from "@app/types/filter";
import {filters} from "@app/api/endpoints";

export default class FiltersApi {
    constructor(private readonly api: AppApi) {}

    public getAll(params: FilterParams): Promise<Filter> {
        return this.api.client.get<null, Filter>(filters.getAll(), { params: params })
    }
}