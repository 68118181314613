import { IFloor } from "@app/types/facilityTypes"
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { resetSelectedRiser } from "src/slices/riser-slice"
import { AppDispatch, RootState } from "src/store"
import { getAvailablePropsThunk } from "./get-available-props-thunk"

export const selectFloorThunk = createAsyncThunk<number, IFloor | number, { dispatch: AppDispatch, state: RootState }>(
    "@@floor/select",
    (data, { dispatch }) => {
        const id = typeof data === "number" ? data : data.id
        dispatch(getAvailablePropsThunk(id))
        dispatch(resetSelectedRiser())
        return id
    }
)

export function selectFloorFulfilled(state: RootState["floorSlice"], { payload }: PayloadAction<number>) {
    state.selectedId = payload
}