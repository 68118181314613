import { FloorState } from "@app/types/facilityTypes";

export const initialState: FloorState = {
    availablePropsIsLoading: false,
    createInProcess: false,
    floors: [],
    isFetching: false,
    isLoading: false,
    removeInProcess: false,
    updateInProcess: false,    
}