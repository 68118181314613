import AppApi from "@app/api/index";
import {Announcement, CreateAnnouncementDto, UpdateAnnouncementDto} from "@app/types/announcement";
import {announcement} from "@app/api/endpoints";

export default class AnnouncementApi {
    constructor(private readonly api: AppApi) {}

    public getAll(): Promise<Announcement[]> {
        return this.api.client.get<null, Announcement[]>(announcement.getAll())
    }

    public create(dto: CreateAnnouncementDto): Promise<Announcement> {
        return this.api.client.post<CreateAnnouncementDto, Announcement>(announcement.create(), dto)
    }

    public update(id: number, dto: UpdateAnnouncementDto): Promise<Announcement> {
        return this.api.client.patch<UpdateAnnouncementDto, Announcement>(announcement.update(id), dto)
    }

    public getById(id: number): Promise<Announcement> {
        return this.api.client.get<null, Announcement>(announcement.getById(id))
    }

    public remove(id: number): Promise<boolean> {
        return this.api.client.delete<null, boolean>(announcement.remove(id))
    }
}
