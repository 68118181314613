import { api } from '@app/api';
import { IHouse } from '@app/types/houseTypes';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';

export const getOneHouseThunk = createAsyncThunk<IHouse, number, { dispatch: AppDispatch, state: RootState }>(
    '@@houses/get-by-id',
    async (id) => {
        return await api.house.getById(id)
    }
)

export function getOneHousePending(state: RootState['houseSlice']) {
    state.houseIsLoading = !state.selectedHouse
}

export function getOneHouseRejected(state: RootState["houseSlice"]) {
	state.houseIsLoading = false
}

export function getOneHouseFulfilled(state: RootState['houseSlice'], { payload }: PayloadAction<IHouse>) {
    state.houseIsLoading = false
    state.selectedHouse = payload
}
