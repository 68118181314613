import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { UpdateRiser } from "@app/types/facilityTypes"
import { api } from "@app/api"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { getRisersBySectionThunk } from "./get-risers-by-section-thunk"

export const updateRiserThunk = createAsyncThunk<void, UpdateRiser, { dispatch: AppDispatch; state: RootState }>(
	"@@riser/update",
	async (data, { dispatch }) => {
		try {
			const text = await api.riser.update(data)
			dispatch(getRisersBySectionThunk(undefined))
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function updateRiserThunkPending(state: RootState["riserSlice"]) {
	state.updateRiserInProcess = true
}

export function updateRiserThunkRejected(state: RootState["riserSlice"]) {
	state.updateRiserInProcess = false
}

export function updateRiserThunkFulfilled(state: RootState["riserSlice"]) {
	state.updateRiserInProcess = false
}
