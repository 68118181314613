import { getAllCitiesThunk } from 'src/slices/geoGuidCitiesSlice/thunks/getAllCitiesThunk';
import { getCityThunk } from './getCityThunk';
import { CityType } from "@app/types/geoGuidTypes"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';

export const renameCityThunk = createAsyncThunk<void, CityType, { dispatch: AppDispatch }>("@@cities/rename", async (dto, { dispatch }) => {
	await api.city.rename(dto)
	dispatch(getAllCitiesThunk())
	dispatch(getCityThunk(dto.id))
})

export function renameCityThunkPending(state: RootState['geoGuidCitiesSlice']) {
    state.renameCityIsLoading = true
}

export function renameCityThunkRejected(state: RootState["geoGuidCitiesSlice"]) {
	state.renameCityIsLoading = false
}

export function renameCityThunkFullfilled(state: RootState["geoGuidCitiesSlice"]) {
	state.renameCityIsLoading = false
	state.showRenameCityModal = false
}