import AppApi from "./index";
import {Agent, AgentBanRequest, AgentFull, CreateAgentRequest, UpdateAgentRequest} from "../types/agent";
import {Request} from "../types/request";
import {Pagination} from "../types/pagination";

export default class AgentApi {

    constructor(private readonly api: AppApi) {
    }

    public getAll(): Promise<Agent[]> {
        return this.api.client.get<null, Agent[]>('/agents')
    }

    public getWithPagination(page: number, limit: number, name?: string) {
        return this.api.client.get<null, Pagination<Agent[]>>('/agents', {params: {page, limit, name}})
    }

    public create(agent: CreateAgentRequest) {
        return this.api.client.post<CreateAgentRequest, AgentFull>('/agents', agent)
    }

    public update(id: number, agent: UpdateAgentRequest) {
        return this.api.client.patch<UpdateAgentRequest>('/agents/' + id, agent)
    }

    public delete(id: number) {
        return this.api.client.delete('/agents/' + id)
    }

    public getById(id: number) {
        return this.api.client.get<null, AgentFull>('/agents/' + id)
    }

    public ban(request: AgentBanRequest) {
        return this.api.client.post<AgentBanRequest, boolean>('/agents/ban', request)
    }

    public getRequests(page: number, limit: number) {
        return this.api.client.post<null, Pagination<Request[]>>('/agents/requests', {limit, page})
    }

    public acceptRequest(requestId: number, accept: boolean) {
        return this.api.client.patch<null, boolean>(`/agents/acceptUpdate/${requestId}/${accept}`)
    }

    public find(name: string) {
        return this.api.client.get<null, Agent[]>(`/agents/find/${name}`)
    }
}
