import { RootState } from "src/store"
import { Facility } from '@app/types/facility';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from "@app/api";

export const getFacilityThunk = createAsyncThunk<Facility, number>(
    '@@facility/get-by-id',
    async (facilityId) => await api.facility.getById(facilityId)
)

export function getFacilityPending(state: RootState["facilitySlice"]) {
	state.facilityIsLoading = true
}

export function getFacilityRejected(state: RootState["facilitySlice"]) {
	state.facilityIsLoading = false
}

export function getFacilityFulfilled(state: RootState['facilitySlice'], { payload }: PayloadAction<Facility>) {
    state.facilityIsLoading = false
    state.facility = payload
} 