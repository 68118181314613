import { PayloadAction } from '@reduxjs/toolkit';
import { getAllCountriesThunk, getAllCountriesThunkFullfilled, getAllCountriesThunkPending, getAllCountriesThunkRejected } from './thunks/getAllCountriesThunk';
import { renameCountryThunk, renameCountryThunkFullfilled, renameCountryThunkPending, renameCountryThunkRejected } from './thunks/renameCountryThunk';
import { getCountryThunk, getCountryThunkFullfilled, getCountryThunkPending, getCountryThunkRejected } from './thunks/getCountryThunk';
import { createCountryThunk, createCountryThunkFullfilled, createCountryThunkPending, createCountryThunkRejected } from './thunks/createCountryThunk';
import { GeoGuidCountriesStateType } from '@app/types/geoGuidTypes';
import { createSlice } from '@reduxjs/toolkit';
import { deleteCountryThunk, deleteCountryThunkFullfilled, deleteCountryThunkPending, deleteCountryThunkRejected } from './thunks/deleteCountryThunk';

const initialState: GeoGuidCountriesStateType = {
	countries: [],
	createCountryIsLoading: false,
	countriesIsLoading: false,
	getCountryIsLoading: false,
	renameCountryIsLoading: false,
	deleteCountryIsLoading: false,
	deleteCountryIsSuccess: false,
	showAddCountryModal: false,
	showDeleteCountryModal: false,
	showRenameCountryModal: false,
}

const geoGuidSlice = createSlice({
	extraReducers: (build) => {
		build.addCase(createCountryThunk.pending, createCountryThunkPending)
		build.addCase(createCountryThunk.rejected, createCountryThunkRejected)
		build.addCase(createCountryThunk.fulfilled, createCountryThunkFullfilled)

		build.addCase(getAllCountriesThunk.pending, getAllCountriesThunkPending)
		build.addCase(getAllCountriesThunk.rejected, getAllCountriesThunkRejected)
		build.addCase(getAllCountriesThunk.fulfilled, getAllCountriesThunkFullfilled)

		build.addCase(getCountryThunk.pending, getCountryThunkPending)
		build.addCase(getCountryThunk.rejected, getCountryThunkRejected)
		build.addCase(getCountryThunk.fulfilled, getCountryThunkFullfilled)

		build.addCase(renameCountryThunk.pending, renameCountryThunkPending)
		build.addCase(renameCountryThunk.rejected, renameCountryThunkRejected)
		build.addCase(renameCountryThunk.fulfilled, renameCountryThunkFullfilled)

		build.addCase(deleteCountryThunk.pending, deleteCountryThunkPending)
		build.addCase(deleteCountryThunk.rejected, deleteCountryThunkRejected)
		build.addCase(deleteCountryThunk.fulfilled, deleteCountryThunkFullfilled)
	},
	initialState,
	name: "geoGuidCountriesSlice",
	reducers: {
		resetCountry(state) {
			state.country = undefined
		},
		resetDeleCountryIsSuccess(state) {
			state.deleteCountryIsSuccess = false
		},
		setShowAddCountryModal(state, { payload }: PayloadAction<boolean>) {
			state.showAddCountryModal = payload
		},
		setShowDeleteCountryModal(state, { payload }: PayloadAction<boolean>) {
			state.showDeleteCountryModal = payload
		},
		setShowRenameCountryModal(state, { payload }: PayloadAction<boolean>) {
			state.showRenameCountryModal = payload
		},
	},
})

export default geoGuidSlice

export const { resetCountry, resetDeleCountryIsSuccess, setShowAddCountryModal, setShowDeleteCountryModal, setShowRenameCountryModal } = geoGuidSlice.actions