import { cities } from "@app/api/endpoints";
import AppApi from "@app/api/index";
import { ChangeCoordsCityType, CityType, CreateCityType, ICity } from "@app/types/geoGuidTypes"

export default class CityApi {
	constructor(private readonly api: AppApi) {}

	public createCity(dto: CreateCityType) {
		return this.api.client.post<CreateCityType, void>(cities.create(), dto)
	}

	public getAll(countryId: number) {
		return this.api.client.get<void, CityType[]>(cities.getAll(countryId))
	}

	public getById(cityId: number) {
		return this.api.client.get<number, CityType>(cities.getById(cityId))
	}

	public getBySubstring(substring: string) {
		return this.api.client.get<number, ICity[]>(cities.getBySubstring(substring))
	}

	public changeCoords(data: ChangeCoordsCityType) {
		return this.api.client.put<ChangeCoordsCityType, string>(cities.changeCoords(), data)
	}

	public rename(data: CityType) {
		return this.api.client.put<CreateCityType, string>(cities.rename(), data)
	}

	public delete(cityId: number) {
		return this.api.client.delete<string, string>(cities.delete(cityId))
	}
}