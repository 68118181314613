import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { AvailableFloorProps } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";

export const getAvailablePropsThunk = createAsyncThunk<AvailableFloorProps, number | undefined, { dispatch: AppDispatch; state: RootState }>(
	"@@floor/get-available-props",
	async (id, { dispatch, getState }) => {
		const { floorSlice } = getState()
		const floorId = id || floorSlice.selectedId
		if (!floorId) {
			dispatch(alertComponentShow({ severity: "error", text: "Этаж не определён" }))
		}
		try {
			return await api.floor.getAvailableProps(floorId)
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function getAvailablePropsPending(state: RootState["floorSlice"]) {
    state.availablePropsIsLoading = true
	state.availableProps = undefined
}

export function getAvailablePropsRejected(state: RootState["floorSlice"]) {
    state.availablePropsIsLoading = false
}

export function getAvailablePropsFulfilled(state:RootState["floorSlice"], { payload }: PayloadAction<AvailableFloorProps>) {
    state.availablePropsIsLoading = false
	state.availableProps = payload
}