import { BuildingsState } from "@app/types/facilityTypes";

export const initialState: BuildingsState = {
    buildings: [],
    buildingsIsFetching: false,
    buildingsIsLoading: false,
    createBuildingInProcess: false,
    removeBuildingInProcess: false,
    updateBuildingInProcess: false,
    createSuccess: false,
}