import { Floor, FloorType } from "@app/types/checkerboard";

const renameFloors = (floors: Floor[]) => {
    let mansardaFloors = floors
        .filter((value) => value.type === FloorType.Mansarda)
        .map((f) => {
            return { ...f, name: "М" };
        });
    let residentialFloors = floors.filter((value) => value.type === FloorType.Residential);
    let commercialFloors = floors
        .filter((value) => value.type === FloorType.Commercial)
        .map((f) => {
            return { ...f, name: "К" };
        });
    let minusFloors = floors.filter((value) => value.type === FloorType.Minus).reverse();
    let groundFloors = floors
        .filter((value) => value.type === FloorType.Ground)
        .map((f) => {
            return { ...f, name: "Ц" };
        });
    let parkingFloors = floors
        .filter((value) => value.type === FloorType.Parking)
        .map((f) => {
            return { ...f, name: "П" };
        });

    let maxFloor = 0;

    residentialFloors = residentialFloors.map((f) => {
        maxFloor += 1;
        return {
            ...f,
            name: String(maxFloor),
            sort_order: 0,
            type: FloorType.Residential,
        };
    });

    let minFloor = 0;
    minusFloors = minusFloors.map((f) => {
        minFloor -= 1;
        return {
            ...f,
            name: String(minFloor),
            sort_order: 0,
            type: FloorType.Minus,
        };
    });

    const nextFloors = [
        ...parkingFloors,
        ...groundFloors,
        ...minusFloors.reverse(),
        ...commercialFloors,
        ...residentialFloors,
        ...mansardaFloors,
    ].map((f, i) => {
        return {
            ...f,
            sort_order: i,
        };
    });

    return nextFloors;
};

export default renameFloors;
