import {UploadFileResponse} from "./file";
import {PaginationGet} from "@app/types/pagination";
import {FilterParams} from "@app/types/filter";
import { IArea, ICity } from "./geoGuidTypes";
import { Developer, DevelopersContacts } from "./developer";

export type FacilitySort = 'pubdate' | 'pricedesc' | 'priceasc' | 'byname'

export interface GetFacilitiesParams extends PaginationGet {
    sort: FacilitySort
    searchQuery?: string
    category?: FacilityCategory
    developerId?: number
    ownerId?: number
}

export interface GetFacilitiesWithFilters extends GetFacilitiesParams, FilterParams {
}

export interface Facility {
    id: number
    facilityType: FacilityType
    photoNames: string[]
    name: string
    cX: number
    cY: number
    address: string
    pricePerMeter: number
    description: string
    district: string
    microDistrict: string
    flatsCount?: number
    minPrice: number
    maxPrice: number
    minSquare: number
    maxSquare: number
    material: string
    parking: string
    territory: string
    ceilingHeight: number
    registrations: FacilityRegistration[]
    flatDecoration?: FacilityFlatDecoration
    houseClass?: FacilityHouseClass
    benefits: FacilityBenefit[]
    communications: FacilityCommunication[]
    communicationsValues: string[]
    additionals: string[]
    additionalsValues: string[]
    paymentOptions: FacilityPaymentOption[]
    installmentPlanOptions: string[]
    sales: string[]
    commissions: string[]
    documentNames: string[]
    documents: UploadFileResponse[]
    news: FacilityNews[]
    isIllegal: boolean
    apartmentLayouts: string[]
    apartmentLayoutsTitles: string[]
    building?: string
    area?: IArea | null
    city?: ICity | null
    distanceToTheBeach?: number
    isEnabled: boolean
    checkerboardId?: number
    developerId?: number
    developerName?: string
    developerAvatar?: string
    developerIsMock?: boolean
    developer?: Developer
    developerContacts?: DevelopersContacts[]
    ownerId?: number
    ownerName?: string
    ownerAvatar?: string
    roomCount?: RoomCount
    cottageCount?: number
    square: number
    price: number
    isDelivered: boolean
    deliveryDate: string
    floor?: number
}

export interface CreateFacilityRequest {
    facilityType: FacilityType
    name: string
    cX: number
    cY: number
    address: string
    pricePerMeter: number
    description: string
    district: string
    microDistrict: string
    flatsCount?: number
    minPrice: number
    maxPrice: number
    minSquare: number
    maxSquare: number
    material: string
    parking: string
    territory: string
    ceilingHeight: number
    registrations: FacilityRegistration[]
    flatDecoration?: FacilityFlatDecoration
    houseClass?: FacilityHouseClass
    benefits: FacilityBenefit[]
    communications: FacilityCommunication[]
    communicationsValues: string[]
    additionals: string[]
    additionalsValues: string[]
    paymentOptions: FacilityPaymentOption[]
    installmentPlanOptions: string[]
    sales: string[]
    commissions: string[]
    documentNames: string[]
    news: FacilityNewsCreateDto[]
    photoNames: string[]
    isIllegal: boolean
    apartmentLayouts: string[]
    apartmentLayoutsTitles?: string[]
    building: string
    areaId?: number
    cityId?: number
    distanceToTheBeach?: number
    isEnabled: boolean
    checkerboardId: number
    developerId?: number
    developerContacts?: DevelopersContacts[]
    ownerId?: number
    roomCount?: RoomCount
    cottageCount?: number
    square: number
    price: number
    deliveryDate: string
    floor?: number
}

export enum FacilityType {
    NEW = 'Новостройки',
    COTTAGE = 'Коттеджные поселки',
    FLAT = 'Квартиры'
}

export enum FacilityRegistration {
	FZ214 = "ФЗ-214",
	FZ215 = "ФЗ-215",
	INVESTMENT = "Договор инвестирования",
	PRELIMINARY = "Предварительный договор",
	CONTRIBUTION = "Паевой взнос",
	BOOKING = "Договор бронирования",
	SalesContactLand = "ДКП на земельный участок",
	SalesContract = "Договор купли-продажи",
	HouseBuildingContract = "Договор подряда",
	Justice = "Юстиция",
    LoanAgreement = "Договор займа",
    AssignmentAgreement = "Договор переуступки",
}

export enum FacilityFlatDecoration {
    FINISHING = 'Чистовая',
    PRE_FINISHING = 'Предчистовая',
    REPAIR = 'Ремонт',
    DRAFT = 'Черновая'
}

export enum FacilityHouseClass {
    ECONOMY = 'Эконом',
    COMFORT = 'Комфорт',
    BUSINESS = 'Бизнес',
    ELITE = 'Элит',
}

export enum FacilityCommunication {
    GAS = 'Газ',
    HEATING = 'Отопление',
    ELECTRICITY = 'Электричество',
    SEWERAGE = 'Канализация',
    WATER_SUPPLY = 'Водоснабжение',
    CENTRAL_COMMUNICATIONS = 'Центральные коммуникации'
}

export enum FacilityPaymentOption {
    MORTGAGE = 'Ипотека',
    MILITARY = 'Военная ипотека',
    CAPITAL = 'Материнский капитал',
    INSTALLMENT = 'Рассрочка',
}

export enum FacilityBenefit {
    Parking = 'Паркинг',
    SwimmingPool = 'Бассейн',
    Concierge = 'Консьерж',
    ChillZone = 'Зона отдыха',
    Playground = 'Детская площадка',
    ClosedTerritory = 'Закрытая территория',
    PrivateBeach = 'Собственный пляж',
    PanoramicWindows = 'Панорамные окна',
    SurroundingArea = 'Придомовая территория',
    SurroundingShops = 'Придомовые магазины',
    SportsGround = 'Спортивная площадка',
    TennisCourts = 'Теннисные корты',
    TennisAcademy = 'Теннисная академия',
    ManagingCompany = 'Управляющая компания',
    CentralCommunications = 'Центральные коммуникации',
    SeaView = 'Вид на море',
    VideoIntercom = 'Видеодомофон',
    VideoSecurity = 'Видеонаблюдение',
    ServiceElevator = 'Грузовой лифт',
    CommercialPremises = 'Коммерческие площади',
    FreeLayout = 'Свободная планировка',
    OwnBoiler = 'Собственная котельная',
}


export interface FacilityNews {
    header: string
    text: string
    image?: string
    createdAt: string
}

export interface FacilityNewsCreateDto {
    header: string
    text: string
    image?: string
}

export type FacilityCategory = 'new' | 'newbuilding' | 'village' | 'flat' | 'withsales' | 'popular' | 'isillegal'

export const FacilityCategoryNames: Record<FacilityCategory, string> = {
    newbuilding: 'Жилые комплексы',
    village: 'Коттеджные поселки',
    flat: 'Квартиры',
    new: 'Новинки',
    withsales: 'Акции',
    popular: 'Популярные',
    isillegal: 'Незаконные',
}

export interface UpdateFacilityRequest extends Partial<CreateFacilityRequest> {
}

export enum RoomCount {
    STUDIO = 'Студия',
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
    FIVE = '5',
    SIX = '6',
    SEVEN = '7',
    EIGHT_OR_GREATER = '8+'
}
