import type {User} from '../app/types/user';
import {Roles} from "../app/types/user";
import {createResourceId} from '../utils/create-resource-id';
import {JWT_EXPIRES_IN, JWT_SECRET, sign} from '../utils/jwt';
import {wait} from '../utils/wait';

const users: any[] = [
  // {
  //   id: '5e86809283e28b96d2d38537',
  //   avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
  //   email: 'demo@devias.io',
  //   name: 'Anika Visser',
  //   password: 'Password123!',
  //   plan: 'Premium'
  // }
];

const USER: User = {
  name: 'Test',
  role: Roles.ADMIN
}

class AuthApi {
  async login({ email, password }): Promise<string> {
    await wait(500);

    return new Promise((resolve, reject) => {
      try {
        // Find the user
        const user: any = users.find((_user: any) => _user.email === email);

        if (!user || (user.password !== password)) {
          reject(new Error('Please check your email and password'));
          return;
        }

        // Create the access token
        const accessToken = sign(
          { userId: user.id },
          JWT_SECRET,
          { expiresIn: JWT_EXPIRES_IN }
        );

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async register({ email, name, password }): Promise<string> {
    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user: any = users.find((_user: any) => _user.email === email);

        if (user) {
          reject(new Error('User already exists'));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: null,
          email,
          name,
          password,
          plan: 'Standard'
        };

        users.push(user);

        const accessToken = sign(
          { userId: user.id },
          JWT_SECRET,
          { expiresIn: JWT_EXPIRES_IN }
        );

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        // const { userId } = decode(accessToken) as any;

        // Find the user
        // const user = users.find((_user) => _user.id === userId);
        //
        // if (!user) {
        //   reject(new Error('Invalid authorization token'));
        //   return;
        // }

        resolve(USER);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
