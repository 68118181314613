import { CountryType } from '@app/types/geoGuidTypes';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getAllCountriesThunk = createAsyncThunk<CountryType[]>("@@countries/get-all-countries", async () => await api.country.getAll())

export function getAllCountriesThunkPending(state: RootState["geoGuidCountriesSlice"]) {
	state.countriesIsLoading = !state.countries.length
}

export function getAllCountriesThunkRejected(state: RootState["geoGuidCountriesSlice"]) {
	state.countriesIsLoading = false
}

export function getAllCountriesThunkFullfilled(state: RootState["geoGuidCountriesSlice"], { payload }: PayloadAction<CountryType[]>) {
	state.countriesIsLoading = false
	state.countries = payload
}