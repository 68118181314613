import { ApartmentLink, Floor, Riser } from "@app/types/checkerboard"

/**
 *
 * Ищет индекс этажа квариры в массиве этажей, отсортированном по убыванию
 * 
 * @param {Floor[]} this - массив этажей, отсортированный по убыванию
 * @param {ApartmentLink} apartmentLink - ссылка на объект квартиры
 */
function getFloorIndex(this: Floor[], apartmentLink: ApartmentLink) {
	const index = this.findIndex(({ id }) => id === apartmentLink.floorId)
	if (index === -1) {
		throw new Error(`В этажах ${JSON.stringify(this)} не найдена квартира ${JSON.stringify(apartmentLink)}`)
	}
	return index
}

/**
 *
 * Ищет индекс стояка квариры в массиве стояков, отсортированном по возрастнию
 * 
 * @param {Floor[]} this - массив стояков, отсортированный по возрастнию
 * @param {ApartmentLink} apartmentLink - ссылка на объект квартиры
 */
function getRiserIndex(this: Riser[], apartmentLink: ApartmentLink) {
	const index = this.findIndex(({ id }) => id === apartmentLink.riserId)
	if (index === -1) {
		throw new Error(`В стояках ${JSON.stringify(this)} не найдена квартира ${JSON.stringify(apartmentLink)}`)
	}
	return index
}

/**
 * возвращает количество сдвоенных этажей выше текущего;
 * @param floors - массив этажей, отсортированный по убыванию;
 * @param floorIndex - индекс исследуемого этажа;  
 * @returns 
 */
function getAboveDuplexFloorsCount(floors: Floor[], floorIndex: number) {
	const nextFloors = floors.slice(0, floorIndex)
	const duplexFloorsCount = nextFloors.filter(({ isDuplex }) => !!isDuplex).length
	return duplexFloorsCount
}

/**
 * являются ли все верхние квартиры группы двойными;
 * @param floor - верхний этаж, занимаемый группой;
 * @param apartmentLinks - ссылки на квартиры группы
 * @returns 
 */
function topGroupLinksIsDuplex(floor: Floor, apartmentLinks: ApartmentLink[]) {
	const topGroupLinks = apartmentLinks.filter(({ floorId }) => floorId && floorId === floor.id)
	return topGroupLinks.every(({ apartment }) => apartment?.isDuplex)
}

/**
 * Возвращает положение вершин ячейки квартиры
 *
 * @param {ApartmentLink[]} apartmentLinks - массив объектов сгруппированных квартир с информацией о размещении в здании
 * @param {Floor[]} floors - массив этажей, отсортированный по убыванию номера этажа;
 * @param {Riser[]} risers - массив этажей, отсортированный по возрастаню позиции;
 */
export function getApartmentGroupCellPosition(apartmentLinks: ApartmentLink[], floors: Floor[], risers: Riser[]) {
	const groupsFloors = apartmentLinks.map<number>(getFloorIndex.bind(floors)) // индексы этажей, занимаемых группой;
	const groupsRisers = apartmentLinks.map<number>(getRiserIndex.bind(risers)) // индексы стояков, занимаемых группой;

	// все ячейки квартир нужно сместить вниз и влево относительно верхнего левого угла на 1 ячейку,
	// т.к. линии сетки нумеруются с 1,
	// и ещё на 1, т.к. верхнюю строку и левую колонку занимают этажи и стояки;
	const defaultShiftTop = 2
	const defaultShiftLeft = 2

	const topIndex = Math.min(...groupsFloors) // индекс самого высокого этажа, занимаемого группой;
	const shiftTopByDuplexFloors = getAboveDuplexFloorsCount(floors, topIndex) // смещение вниз на количество двойных этажей выше группы;
	// const topFloorIsDuplex = floors[topIndex]?.isDuplex	// верхний этаж двойной;
	// const topApartmentsIsDuplex = topGroupLinksIsDuplex(floors[topIndex], apartmentLinks)	// все верхние квартиры группы двойные;
	// если верхний этаж двойной, а верхние квартиры не все двойные,
	// то группу нужно сдвинуть вниз на одну ячейку;
	const shiftTopByDuplexApartment = 0 //topFloorIsDuplex ? 1 : 0
	const top = defaultShiftTop + shiftTopByDuplexFloors + shiftTopByDuplexApartment + topIndex // номер верхней строки группы в css-grid;

	const bottomIndex = Math.max(...groupsFloors) // номер самого нижнего этажа, занимаемого группой;
	const groupDuplexFloorsCount = groupsFloors.reduce((acc, item) => floors[item].isDuplex ? acc + 1 : acc, 0)
	const floorsCountKeepedByGroup = Math.max(bottomIndex - topIndex + 1, 1) // количество этажей, занимаемых группой;
	const bottom = top + floorsCountKeepedByGroup + groupDuplexFloorsCount - shiftTopByDuplexApartment // номер нижней строки группы в css-grid;

	const leftIndex = Math.min(...groupsRisers) // индекс самого левого стояка, занимаемого группой;
	const left = defaultShiftLeft + leftIndex // номер левой колонки группы в css-grid;

	const rightIndex = Math.max(...groupsRisers) // индекс самого правого стояка, занимаемого группой;
	const risersCountKeepedByGroup = rightIndex - leftIndex + 1 // количество стояков, занимаемых группой;
	const right = left + risersCountKeepedByGroup // номер правой колонки группы в css-grid;

	return { top, right, bottom, left }
}