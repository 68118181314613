import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { IRiser } from "@app/types/facilityTypes"
import { api } from "@app/api";
import { alertComponentShow } from "src/slices/alertComponentSlice";

export const getRisersBySectionThunk = createAsyncThunk<IRiser[], number | undefined, { dispatch: AppDispatch; state: RootState }>(
    "@@riser/get-by-section",
    async (data, { dispatch, getState }) => {
        try {
            const { sectionSlice } = getState()
            const sectionId = data || sectionSlice.selectedSectionId
            if (!sectionId) {
				return []
			}
            return await api.riser.getBySectionId({ sectionId })
        } catch (e) {
            console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
    }
)

export function getRisersBySectionThunkPending(state: RootState['riserSlice']) {
    state.isLoading = !state.risers.length
    state.isFetching = true
}

export function getRisersBySectionThunkRejected(state: RootState["riserSlice"]) {
	state.isLoading = false
	state.isFetching = false
}

export function getRisersBySectionThunkFulfilled(state: RootState["riserSlice"], { payload }: PayloadAction<IRiser[]>) {
	state.risers = payload
	state.isLoading = false
	state.isFetching = false
}