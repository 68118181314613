import { countries } from "@app/api/endpoints";
import AppApi from "@app/api/index";
import { CountryType, CreateCountryType } from "@app/types/geoGuidTypes";

export default class CountryApi {
	constructor(private readonly api: AppApi) {}

	public createCountry(dto: CreateCountryType) {
		return this.api.client.post<CreateCountryType, void>(countries.create(), dto)
	}

	public getAll() {
		return this.api.client.get<void, CountryType[]>(countries.getAll())
	}

	public getById(countryId: number) {
		return this.api.client.get<number, CountryType>(countries.getById(countryId))
	}

	public rename({ id, title }: CountryType) {
		return this.api.client.put<CreateCountryType, string>(countries.rename(id), { title })
	}

	public delete(countryId: number) {
		return this.api.client.delete<string, string>(countries.delete(countryId))
	}
}