import { createAsyncThunk } from "@reduxjs/toolkit"
import { api } from "@app/api"
import { getCheckerboardThunk } from "./get-checkerboard-thunk"
import { UpdateCheckerboard } from "@app/types/facilityTypes"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { AppDispatch, RootState } from "src/store"

export const updateCheckerboardThunk = createAsyncThunk<void, Omit<UpdateCheckerboard, "id">, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/update",
	async (data, { dispatch, getState }) => {
		try {
			const { checkerboardSlice } = getState()
			const id = checkerboardSlice.checkerboard?.id
			if (!id) {
				dispatch(alertComponentShow({ severity: "error", text: "Шахматка не определена" }))
			}
			const text = await api.checkerboard.update({ ...data, id })
			dispatch(getCheckerboardThunk(id))
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function updateCheckerboardPending(state: RootState["checkerboardSlice"]) {
	state.updateCheckerboardInProcess = true
}

export function updateCheckerboardRejected(state: RootState["checkerboardSlice"]) {
	state.updateCheckerboardInProcess = false
}

export function updateCheckerboardFulfilled(state: RootState["checkerboardSlice"]) {
	state.updateCheckerboardInProcess = false
}
