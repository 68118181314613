import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {RiFunctionLine, RiSettings2Line} from "react-icons/ri";
import {Roles} from "../app/types/user";

interface LogoProps {
  variant?: 'light' | 'primary';
  mode?: Roles
}

export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props;

  const color = '#1F64E5';

  if (props.mode === Roles.MANAGER) {
    return (
        <RiFunctionLine color={color} size={40} />
    )
  }

  return (
    <RiSettings2Line color={color} size={40} />
  );
})``;

Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
