import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { UpdateSection } from "@app/types/facilityTypes"
import { api } from "@app/api"
import { getBuildingsByCheckerboardThunk } from "src/slices/building-slice/thunks/get-buildings-by-checkerboard-thunk"
import { alertComponentShow } from "src/slices/alertComponentSlice"

export const updateSectionThunk = createAsyncThunk<void, UpdateSection, { dispatch: AppDispatch; state: RootState }>(
	"@@section/update",
	async (data, { dispatch }) => {
		try {
			const text = await api.section.update(data)
			dispatch(getBuildingsByCheckerboardThunk(undefined))
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function updateSectionThunkPending(state: RootState["sectionSlice"]) {
	state.updateSectionInProcess = true
}

export function updateSectionThunkRejected(state: RootState["sectionSlice"]) {
	state.updateSectionInProcess = false
}

export function updateSectionThunkFulfilled(state: RootState["sectionSlice"]) {
	state.updateSectionInProcess = false
}
