import AppApi from "./api";
import React, {useContext} from "react";

interface IAppContext {
    api: AppApi
}

const AppContext = React.createContext<null | IAppContext>(null)

export const useAppContext = () => {
    const context = useContext(AppContext)
    return context as IAppContext
}

export default AppContext