import AppApi from "./index";
import {CreateManagerRequest, Manager, ManagerBanRequest, UpdateManagerRequest} from "../types/manager";
import {Pagination} from "../types/pagination";

export default class ManagerApi {
    constructor(private api: AppApi) {}

    public getAll() {
        return this.api.client.get<null, Manager[]>('/managers')
    }

    public getWithPagination(page: number, limit: number, name?: string) {
        return this.api.client.get<null, Pagination<Manager[]>>('/managers', { params: { page, limit, name }  })
    }

    public create(manager: CreateManagerRequest) {
        console.log(manager)
        return this.api.client.post<CreateManagerRequest, Manager>('/managers', manager)
    }

    public update(id: number, manager: UpdateManagerRequest) {
        return this.api.client.patch<UpdateManagerRequest>('/managers/' + id, manager)
    }

    public delete(id: number) {
        return this.api.client.delete('/managers/' + id)
    }

    public getById(id: number) {
        return this.api.client.get<null, Manager>('/managers/' + id)
    }

    public ban(request: ManagerBanRequest) {
        return this.api.client.post<ManagerBanRequest, boolean>('/managers/ban', request)
    }

    public find(name: string) {
        return this.api.client.get<null, Manager[]>(`/managers/find/${name}`)
    }
}