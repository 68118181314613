import { AppDispatch, RootState } from './../../../store/index';
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { Checkerboard, CheckerboardFilters, PlateboardStateType } from "@app/types/checkerboard"
import { setBuilding, setSection } from '../plateboardSlice'
import { api } from "@app/api"
import { filterResponseToParams } from '@app/types/checkerboard';

type GetCheckerboardParams = {
	checkerboardId: number
	filtered?: Partial<CheckerboardFilters>
}

export const getPlateboardThunk = createAsyncThunk<Checkerboard, GetCheckerboardParams, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/get-plateboard",
	async ({ checkerboardId, filtered }, thunkApi) => {
		const filters = filterResponseToParams(filtered)
		const { dispatch, getState } = thunkApi
		const { plateboard } = getState()
		const checkerboard = await api.checkerboard.getCheckerboardById(checkerboardId, filters)
		const buildingId = plateboard.building?.id
		const sectionId = plateboard.section?.id

		const { buildings } = checkerboard
		const building = buildings.find(({ id }) => id === buildingId) || buildings[0]
		if ( building ) {
			const { sections } = building
			const section = sections.find(({ id }) => id === sectionId) || sections[0]
			dispatch(setBuilding(building))
			dispatch(setSection(section))
		}
		return checkerboard
	}
)

export function getPlateboardPending(state: PlateboardStateType) {
	if (!state.checkerboard) {
		state.checkerboardIsLoading = true
	}
	state.checkerboardIsFetching = true
}

export function getPlateboardFulfilled(state: PlateboardStateType, { payload }: PayloadAction<Checkerboard>) {
	state.checkerboardIsFetching = false
	state.checkerboardIsLoading = false
	state.selectedCells = []
	state.checkerboard = payload
	if ( state.selectedFloor ) {
		const floors = payload.buildings.reduce((acc, { sections }) => {
			const floors = sections.reduce((acc, { floors }) => {
				acc.push(...floors)
				return acc
			}, [])
			acc.push(...floors)
			return acc
		}, [])
		const selectedFloor = floors.find(({ id }) => (id === state.selectedFloor.id))
		state.selectedFloor = selectedFloor
	}
}

export function getPlateboardRejected(state: PlateboardStateType) {
	state.checkerboardIsFetching = false
	state.checkerboardIsLoading = false
	state.selectedCells = []
	state.checkerboard = undefined
	delete state.checkerboard
}