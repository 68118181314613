import { areas } from "@app/api/endpoints";
import AppApi from "@app/api/index";
import { AreaType, CreateAreaType } from "@app/types/geoGuidTypes"

export default class AreaApi {
	constructor(private readonly api: AppApi) {}

	public createArea(dto: CreateAreaType) {
		return this.api.client.post<CreateAreaType, void>(areas.create(), dto)
	}

	public getAll(cityId: number) {
		return this.api.client.get<void, AreaType[]>(areas.getAll(cityId))
	}

	public getById(areaId: number) {
		return this.api.client.get<number, AreaType>(areas.getById(areaId))
	}

	public rename(data: AreaType) {
		return this.api.client.put<CreateAreaType, void>(areas.rename(), data)
	}

	public delete(areaId: number) {
		return this.api.client.delete<string, void>(areas.delete(areaId))
	}
}