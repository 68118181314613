import AppApi from "./index";
import { AddBuilding, GetBuildingsByCheckerboard, IBuilding, UpdateBuilding } from "@app/types/facilityTypes";
import { building } from "./endpoints";
import { RemoveBuilding } from "@app/types/facilityTypes/buildingTypes";

export default class BuildingApi {
    constructor(private api: AppApi) {}

    public create(request: AddBuilding) {
        return this.api.client.post<AddBuilding, string>(building.create(), request)
    }

    public getByCheckerboard({ checkerboardId }: GetBuildingsByCheckerboard) {
        return this.api.client.get<void, IBuilding[]>(building.getByCheckerboard(checkerboardId))
    }

    public update(request: UpdateBuilding) {
        return this.api.client.put<UpdateBuilding, string>(building.update(), request)
    }

    public delete({ id }: RemoveBuilding) {
        return this.api.client.delete<void, string>(building.delete(id))
    }
}