import { api } from "@app/api";
import { IHouse } from "@app/types/houseTypes";
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store"

export const removePhotoThunk = createAsyncThunk<IHouse, number, { dispatch: AppDispatch, state: RootState }>(
    "@@house/remove-photo",
    async (fileIndex, { dispatch, getState }) => {
        try {
            const state = getState()
			const { selectedHouse } = state.houseSlice
			if (!selectedHouse) {
				throw new Error("Дом не определён")
			}

            const { id } = selectedHouse
            const photos = selectedHouse.photos.filter((_, index) => (index !== fileIndex))
            const photoNames = selectedHouse.photoNames.filter((_, index) => index !== fileIndex)
			return await api.house.update({ id, photos, photoNames })
        } catch (e) {
            console.warn(e)
            const text = e?.response?.data?.error_message || e?.message || "Ошибка загрузки изображения..."
			dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function removePhotoPending(state: RootState['houseSlice']) {
}

export function removePhotoRejected(state: RootState["houseSlice"]) {
}

export function removePhotoFulfilled(state: RootState["houseSlice"], { payload }: PayloadAction<IHouse>) {
    const index = state.houses.findIndex(({ id }) => id === payload.id)
    if (index !== -1) {
		state.houses[index] = payload
	}
	state.selectedHouse = payload
}