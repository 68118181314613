import { CellPositionType, Floor, PlateboardCellType, Riser } from "@app/types/checkerboard";

/**
 * Находится ли ячейка в прямогульнике
 * @param cellPosition - исследуемая ячейка
 * @param rectPosition - исследуемый прямоугольник
 * @returns 
 */
function hasRectCell(this: CellPositionType, rectPosition: CellPositionType) {
    const { left, top } = this
    const rectTop = rectPosition.top
    const rectLeft = rectPosition.left
    const rectBottom = rectPosition.bottom || rectTop
    const rectRight = rectPosition.right || rectLeft
    const floorMatch = rectTop <= top && rectBottom > top
    const riserMatch = rectLeft <= left && rectRight > left
    return floorMatch && riserMatch
}

/**
 * 
 * Находится ли ячейка в одной из квартир массива this
 * 
 * @param {CellPositionType[]} this - массив координат квартир
 * @param {CellPositionType} cellPosition - исследуемая ячейка 
 */
function cellNotCoverApart(this: CellPositionType[], cellPosition: CellPositionType) {
    const coordsMatch = hasRectCell.bind(cellPosition)
    return !this.some(coordsMatch)
}

/**
 * возвращает количество сдвоенных этажей выше текущего;
 * @param floors - массив этажей, отсортированный по убыванию;
 * @param floorIndex - индекс исследуемого этажа;  
 * @returns 
 */
function getAboveDuplexFloorsCount(floors: Floor[], floorIndex: number) {
	const nextFloors = floors.slice(0, floorIndex)
	const duplexFloorsCount = nextFloors.filter(({ isDuplex }) => !!isDuplex).length
	return duplexFloorsCount
}

export function getEmptyCells(floors: Floor[], risers: Riser[], apartmentCells: PlateboardCellType[], sectionId: string): PlateboardCellType[] {
	// все ячейки квартир нужно сместить вниз и влево относительно верхнего левого угла на 1 ячейку,
	// т.к. линии сетки нумеруются с 1,
	// и ещё на 1, т.к. верхнюю строку и левую колонку занимают этажи и стояки;
	const defaultShiftTop = 2
	const defaultShiftLeft = 2

    const { cells } = floors.reduce<{ cells: PlateboardCellType[]; top: number }>(
		({ cells, top }, { id, isDuplex }) => {
			const floorId = id.toString()
			const floorCells = risers.map((item, index) => {
				const riserId = item.id.toString()
				const left = defaultShiftLeft + index
				const id = `${sectionId}_${left}_${top}`
				const bottom = isDuplex ? top + 2 : top + 1;
				return { cellPosition: { left, top, bottom }, floorId, riserId, id }
			})
			cells.push(...floorCells)
			top += isDuplex ? 2 : 1;
			return { cells, top }
		},
		{ cells: [], top: defaultShiftTop }
	)
	
	const isEmptyCell = cellNotCoverApart.bind(apartmentCells.map(({ cellPosition }) => cellPosition))
	const emptyCells = cells.filter(({ cellPosition }) => isEmptyCell(cellPosition))
	return emptyCells
}