import { changeCoordsCityThunk, changeCoordsCityThunkFullfilled, changeCoordsCityThunkPending, changeCoordsCityThunkRejected } from './thunks/changeCoordsCityThunk';
import { renameCityThunk, renameCityThunkFullfilled, renameCityThunkPending, renameCityThunkRejected } from './thunks/renameCityThunk';
import { getCityThunk, getCityThunkFullfilled, getCityThunkPending, getCityThunkRejected } from './thunks/getCityThunk';
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GeoGuidCitiesStateType } from "@app/types/geoGuidTypes"
import { getAllCitiesThunk, getAllCitiesThunkFullfilled, getAllCitiesThunkPending, getAllCitiesThunkRejected } from "./thunks/getAllCitiesThunk"
import { createCityThunk, createCityThunkFullfilled, createCityThunkPending, createCityThunkRejected } from "./thunks/createCityThunk"
import { deleteCityThunk, deleteCityThunkFullfilled, deleteCityThunkPending, deleteCityThunkRejected } from './thunks/deleteCityThunk';

const initialState: GeoGuidCitiesStateType = {
	cities: [],
	createCityIsLoading: false,
	citiesIsLoading: false,
	getCityIsLoading: false,
	renameCityIsLoading: false,
	changeCoordsCityIsLoading: false,
	deleteCityIsLoading: false,
	deleteCityIsSuccess: false,
	showAddCityModal: false,
	showChangeCoordsCityModal: false,
	showDeleteCityModal: false,
	showRenameCityModal: false,
}

const geoGuidCitiesSlice = createSlice({
	extraReducers: (build) => {
		build.addCase(createCityThunk.pending, createCityThunkPending)
		build.addCase(createCityThunk.rejected, createCityThunkRejected)
		build.addCase(createCityThunk.fulfilled, createCityThunkFullfilled)

		build.addCase(getAllCitiesThunk.pending, getAllCitiesThunkPending)
		build.addCase(getAllCitiesThunk.rejected, getAllCitiesThunkRejected)
		build.addCase(getAllCitiesThunk.fulfilled, getAllCitiesThunkFullfilled)

		build.addCase(getCityThunk.pending, getCityThunkPending)
		build.addCase(getCityThunk.rejected, getCityThunkRejected)
		build.addCase(getCityThunk.fulfilled, getCityThunkFullfilled)

		build.addCase(renameCityThunk.pending, renameCityThunkPending)
		build.addCase(renameCityThunk.rejected, renameCityThunkRejected)
		build.addCase(renameCityThunk.fulfilled, renameCityThunkFullfilled)

		build.addCase(changeCoordsCityThunk.pending, changeCoordsCityThunkPending)
		build.addCase(changeCoordsCityThunk.rejected, changeCoordsCityThunkRejected)
		build.addCase(changeCoordsCityThunk.fulfilled, changeCoordsCityThunkFullfilled)

		build.addCase(deleteCityThunk.pending, deleteCityThunkPending)
		build.addCase(deleteCityThunk.rejected, deleteCityThunkRejected)
		build.addCase(deleteCityThunk.fulfilled, deleteCityThunkFullfilled)
	},
	initialState,
	name: "geoGuidCitiesSlice",
	reducers: {
		resetCity(state) {
			state.city = undefined
		},
		resetDeleCityIsSuccess(state) {
			state.deleteCityIsSuccess = false
		},
		setShowAddCityModal(state, { payload }: PayloadAction<boolean>) {
			state.showAddCityModal = payload
		},
		setShowChangeCoordsCityModal(state, { payload }: PayloadAction<boolean>) {
			state.showChangeCoordsCityModal = payload
		},
		setShowDeleteCityModal(state, { payload }: PayloadAction<boolean>) {
			state.showDeleteCityModal = payload
		},
		setShowRenameCityModal(state, { payload }: PayloadAction<boolean>) {
			state.showRenameCityModal = payload
		},
	},
})

export default geoGuidCitiesSlice

export const {
	resetCity,
	resetDeleCityIsSuccess,
	setShowAddCityModal,
	setShowChangeCoordsCityModal,
	setShowDeleteCityModal,
	setShowRenameCityModal,
} = geoGuidCitiesSlice.actions
