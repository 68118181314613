import { PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteRiserThunk = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/delete-riser",
	async (_, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, risers: stateRisers, section: stateSection, selectedRiser } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection || !selectedRiser) {
			return
		}

		// удаление стояка из массива имеющихся;
		const risers = stateRisers.filter(({ id }) => id !== selectedRiser.id).map((item, index) => ({ ...item, position: index + 1 }))

		// замена секции с удалёленным стояком в массиве секций строения;
		const section = { ...stateSection, risers }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с удалёленным стояком в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function deleteRiserPending(state: PlateboardStateType) {
	state.deleteRiserIsLoading = true
}

export function deleteRiserFulfilled(state: PlateboardStateType) {
	state.deleteRiserIsLoading = false
	state.showRiserDialog = false
}

export function deleteRiserRejected(state: PlateboardStateType) {
	state.deleteRiserIsLoading = false
}