import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { CheckerboardFilters } from "@app/types/checkerboard";
import { FacilityFlatDecoration } from "@app/types/facility";

type StateFilters = {
    all: CheckerboardFilters;
    filtered?: Partial<CheckerboardFilters>;
    loaded: boolean;
};

type InitialState = {
    loading: boolean;
    value?: StateFilters;
};

export const initFiltersThunk = createAsyncThunk("@@checkerboard/filters", async (checkerboardId: number) => {
	return await api.checkerboard.getFilters(checkerboardId, {})
})

export const loadFiltersThunk = createAsyncThunk("@@checkerboard/filtersLoad", async (checkerboardId: number) => {
	return await api.checkerboard.getFilters(checkerboardId, {})
})

const initialState: InitialState = {
    loading: false,
};

const emptyFilters: CheckerboardFilters = {
    decorations: [],
    deliveryDates: [],
};

const slice = createSlice({
    name: "checkerboardFilters",
    initialState: initialState,
    reducers: {
        reset(state: InitialState): void {
            state.value = undefined;
            state.loading = false;
        },
        clear(state: InitialState): void {
            state.value = { all: emptyFilters, filtered: emptyFilters, loaded: false }
        },
        initFilters(state: InitialState, action: PayloadAction<CheckerboardFilters>): void {
            state.value = {
                all: action.payload,
                filtered: {},
                loaded: true,
            };
        },
        loadFilters(state: InitialState, action: PayloadAction<CheckerboardFilters>): void {
            state.value = {
                ...state.value,
                all: action.payload,
            };
        },
        setPrice(state: InitialState, action: PayloadAction<number[]>): void {
            const [priceGte, priceLte] = action.payload;
            state.value.filtered.priceGte = priceGte;
            state.value.filtered.priceLte = priceLte;
        },
        setPricePerMeter(state: InitialState, action: PayloadAction<number[]>): void {
            const [pricePerMeterGte, pricePerMeterLte] = action.payload;
            state.value.filtered.pricePerMeterGte = pricePerMeterGte;
            state.value.filtered.pricePerMeterLte = pricePerMeterLte;
        },
        setSquare(state: InitialState, action: PayloadAction<number[]>): void {
            const [squareGte, squareLte] = action.payload;
            state.value.filtered.squareGte = squareGte;
            state.value.filtered.squareLte = squareLte;
        },
        addDecoration(state: InitialState, action: PayloadAction<FacilityFlatDecoration>): void {
            state.value.filtered.decorations = [...(state.value.filtered.decorations || []), action.payload];
        },
        removeDecoration(state: InitialState, action: PayloadAction<FacilityFlatDecoration>): void {
            if (state.value.filtered.decorations) {
                state.value.filtered.decorations = state.value.filtered.decorations.filter((d) => d != action.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initFiltersThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(initFiltersThunk.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.value = {
                    all: payload,
                    filtered: {
                        priceGte: undefined,
                        priceLte: undefined,
                        pricePerMeterGte: undefined,
                        squareGte: undefined,
                        squareLte: undefined,
                    },
                    loaded: true,
                };
            })
            .addCase(initFiltersThunk.rejected, (state) => {
                state.loading = false;
            })
            .addCase(loadFiltersThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadFiltersThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.value.all = action.payload;
                state.value.filtered = {
                    priceGte: undefined,
                    priceLte: undefined,
                    pricePerMeterGte: undefined,
                    squareGte: undefined,
                    squareLte: undefined,
                };
            });
    },
});

export const { reducer } = slice;

export const { clear, reset, setPrice, removeDecoration, addDecoration, setPricePerMeter, setSquare } = slice.actions;
