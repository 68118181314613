import { getFacilityFulfilled, getFacilityPending, getFacilityRejected, getFacilityThunk } from "./thunks/getFacilityThunk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FacilityState } from "@app/types/facilityTypes"
import { Facility } from "@app/types/facility"

const initialState: FacilityState = {
	facilityIsLoading: false,
}

const facilitySlice = createSlice({
	extraReducers: (build) => {
        build.addCase(getFacilityThunk.pending, getFacilityPending)
        build.addCase(getFacilityThunk.rejected, getFacilityRejected)
        build.addCase(getFacilityThunk.fulfilled, getFacilityFulfilled)
    },
	initialState,
	name: "facilitySlice",
	reducers: {
		resetFaclityState: () => initialState,
		setFacilityReducer(state, { payload }: PayloadAction<Facility>) {
			state.facility = payload
		}
	},
})

export default facilitySlice

export const { resetFaclityState, setFacilityReducer } = facilitySlice.actions