import { CityType } from "@app/types/geoGuidTypes"
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getAllCitiesThunk = createAsyncThunk<CityType[], void, { state: RootState }>(
	"@@cities/get-all-cities",
	async (_, { getState }) => {
		const state = getState()
		
		const countryId = state.geoGuidCountriesSlice.country?.id
		if ( !countryId ) {
			throw new Error('Страна не определена')
		}
		return await api.city.getAll(countryId)
	}
)

export function getAllCitiesThunkPending(state: RootState["geoGuidCitiesSlice"]) {
	state.citiesIsLoading = !state.cities.length
}

export function getAllCitiesThunkRejected(state: RootState["geoGuidCitiesSlice"]) {
	state.citiesIsLoading = false
}

export function getAllCitiesThunkFullfilled(state: RootState["geoGuidCitiesSlice"], { payload }: PayloadAction<CityType[]>) {
	state.citiesIsLoading = false
	state.cities = payload
}