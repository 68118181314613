import { AddFloor, AvailableFloorProps, IFloor, UpdateFloor } from "@app/types/facilityTypes"
import AppApi from "."
import { floor } from "./endpoints"

export default class FloorApi {
	constructor(private api: AppApi) {}

	public addMany(request: AddFloor) {
		return this.api.client.post<AddFloor, string>(floor.addMany(), request)
	}

	public delete(floorId: number) {
		return this.api.client.delete<void, string>(floor.delete(floorId))
	}

	public update(request: UpdateFloor) {
		return this.api.client.put<UpdateFloor, string>(floor.update(), request)
	}

	public getAvailableProps(floorId: number) {
		return this.api.client.get<void, AvailableFloorProps>(floor.getAvailableProps(floorId))
	}

	public getBySectionId(sectionId: number) {
		return this.api.client.get<void, IFloor[]>(floor.getBySectionId(sectionId))
	}
}