import { IRiser } from "@app/types/facilityTypes"
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { resetSelectedFloor } from "src/slices/floor-slice"
import { AppDispatch, RootState } from "src/store"

export const selectRiserThunk = createAsyncThunk<number, IRiser | number, { dispatch: AppDispatch, state: RootState }>(
    "@@riser/select",
    (data, { dispatch }) => {
        const id = typeof data === "number" ? data : data.id
        dispatch(resetSelectedFloor())
        return id
    }
)

export function selectRiserFulfilled(state: RootState["riserSlice"], { payload }: PayloadAction<number>) {
    state.selectedRiserId = payload
}