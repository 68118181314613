import axios, {AxiosRequestHeaders} from "axios";
import AuthApi from "./auth.api";
import AgentApi from "./agent.api";
import ManagerApi from "./manager.api";
import AgencyApi from "./agency.api";
import FacilityApi from "./facility.api";
import FileApi from "./file.api";
import CheckerboardApi from "@app/api/checkerboard.api";
import OwnerApi from "@app/api/owner.api";
import DeveloperApi from "@app/api/developer.api";
import FiltersApi from "@app/api/filters.api";
import AnnouncementApi from "@app/api/announcement.api";
import ContactApi from "@app/api/contact.api";
import CountryApi from "./country.api";
import CityApi from "./city.api"
import AreaApi from "./area.api"
import HouseApi from "./house.api";
import BuildingApi from "./building.api";
import SectionApi from "./section.api";
import RiserApi from "./riser.api";
import FloorApi from "./floor.api"
import ApartmentApi from "./apartment.api"

export default class AppApi {
	client = axios.create({
		withCredentials: true,
		baseURL: process.env.NEXT_PUBLIC_BASE_URL,
	})

	auth: AuthApi
	agent: AgentApi
	building: BuildingApi
	section: SectionApi
	riser: RiserApi
	floor: FloorApi
	apartment: ApartmentApi
	manager: ManagerApi
	agency: AgencyApi
	area: AreaApi
	facility: FacilityApi
	file: FileApi
	checkerboard: CheckerboardApi
	owner: OwnerApi
	developer: DeveloperApi
	filters: FiltersApi
	announcement: AnnouncementApi
	contact: ContactApi
	country: CountryApi
	city: CityApi
	house: HouseApi

	constructor(token?: string) {
		this.auth = new AuthApi(this)
		this.agent = new AgentApi(this)
		this.building = new BuildingApi(this)
		this.section = new SectionApi(this)
		this.riser = new RiserApi(this)
		this.floor = new FloorApi(this)
		this.apartment = new ApartmentApi(this)
		this.manager = new ManagerApi(this)
		this.agency = new AgencyApi(this)
		this.area = new AreaApi(this)
		this.facility = new FacilityApi(this)
		this.file = new FileApi(this)
		this.checkerboard = new CheckerboardApi(this)
		this.owner = new OwnerApi(this)
		this.developer = new DeveloperApi(this)
		this.filters = new FiltersApi(this)
		this.announcement = new AnnouncementApi(this)
		this.contact = new ContactApi(this)
		this.country = new CountryApi(this)
		this.city = new CityApi(this)
		this.house = new HouseApi(this)

		this.client.interceptors.response.use((res) => res.data)
		this.client.interceptors.request.use((config) => {
			const token = localStorage.getItem("authToken")
			return {
				...config,
				headers: token ? { ...config.headers, Authorization: `Bearer ${token}` } : config.headers,
			}
		})

		if (token) {
			this.client.defaults.headers.common = { Authorization: `Bearer ${token}` }
		}
	}

	public getFilePath(name?: string): string | null {
		if (!name) return null
		return `${this.client.defaults.baseURL}/files/getImage/${name}`
	}
}

export const api = new AppApi()
