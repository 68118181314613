import { AreaType } from "@app/types/geoGuidTypes"
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getAllAreasThunk = createAsyncThunk<AreaType[], void, { state: RootState }>("@@areas/get-all-areas", async (_, { getState }) => {
	const state = getState()
	const cityId = state.geoGuidCitiesSlice.city?.id
	if (!cityId) {
		throw new Error("Город не определен")
	}
	return await api.area.getAll(cityId)
})

export function getAllAreasThunkPending(state: RootState["geoGuidAreasSlice"]) {
	state.areasIsLoading = !state.areas.length
}

export function getAllAreasThunkRejected(state: RootState["geoGuidAreasSlice"]) {
	state.areasIsLoading = false
}

export function getAllAreasThunkFullfilled(state: RootState["geoGuidAreasSlice"], { payload }: PayloadAction<AreaType[]>) {
	state.areasIsLoading = false
	state.areas = payload
}