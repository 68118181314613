import type { FC } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import '../styles/login.module.scss'
import '../styles/Chessboard.scss'
import '../styles/Avatar.scss'
import '../styles/index.scss'
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import type { EmotionCache } from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RTL } from '@components/rtl';
import { SplashScreen } from '@components/splash-screen';
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context';
import { AuthConsumer, AuthProvider } from '../contexts/jwt-context';
import { store } from '../store';
import { createTheme } from '../theme';
import '../i18n';
import AppApi from "../app/api";
import AppContext from "../app/app-context";

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
}

// Router.events.on('routeChangeStart', nProgress.start);
// Router.events.on('routeChangeError', nProgress.done);
// Router.events.on('routeChangeComplete', nProgress.done);
//
// const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);
  const api = new AppApi()

  return (
      <>
      <Head>
        <title>
          Find
        </title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
      </Head>
      <AppContext.Provider value={{ api }}>
        <ReduxProvider store={store}>
            <AuthProvider>
                <SettingsProvider>
                    <SettingsConsumer>
                        {({ settings }) => (
                            <ThemeProvider
                                theme={createTheme({
                                    direction: settings.direction,
                                    responsiveFontSizes: settings.responsiveFontSizes,
                                    mode: 'light',
                                })}
                            >
                                <RTL direction={settings.direction}>
                                    <CssBaseline />
                                    <Toaster position="top-center" />
                                    <AuthConsumer>
                                        {
                                            (auth) => !auth.isInitialized
                                                ? <SplashScreen />
                                                : getLayout(<Component {...pageProps} />)
                                        }
                                    </AuthConsumer>
                                </RTL>
                            </ThemeProvider>
                        )}
                    </SettingsConsumer>
                </SettingsProvider>
            </AuthProvider>
        </ReduxProvider>
      </AppContext.Provider>
        </>
  );
};

export default App;
