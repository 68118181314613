import { api } from "@app/api";
import { AddBuilding } from "@app/types/facilityTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";
import { getBuildingsByCheckerboardThunk } from "./get-buildings-by-checkerboard-thunk";

export const createBuildingThunk = createAsyncThunk<void, Omit<AddBuilding, "checkerboardId">, { dispatch: AppDispatch, state: RootState }>(
    "@@building/create",
    async (data, { dispatch, getState }) => {
        try {
            const { checkerboardSlice } = getState()
            const checkerboardId = checkerboardSlice.checkerboard?.id
            if ( !checkerboardId ) {
                dispatch(alertComponentShow({ severity: "error", text: "Шахматка не определена" }))
            }
            const text = await api.building.create({ ...data, checkerboardId })
            dispatch(getBuildingsByCheckerboardThunk(undefined))
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function createBuildingPending(state: RootState['buildingSlice']) {
    state.createBuildingInProcess = true
    state.createSuccess = false
}

export function createBuildingRejected(state: RootState["buildingSlice"]) {
	state.createBuildingInProcess = false
}

export function createBuildingFulfilled(state: RootState["buildingSlice"]) {
	state.createBuildingInProcess = false
    state.createSuccess = true
}