import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { SectionListItem } from "@app/types/facilityTypes"
import { api } from "@app/api";
import { selectSectionThunk } from "./select-section-thunk";

export const getSectionListThunk = createAsyncThunk<SectionListItem[], number | undefined, { dispatch: AppDispatch; state: RootState }>(
    "@@section/get-list",
    async (data, { dispatch, getState }) => {
        try {
            const { checkerboardSlice, sectionSlice } = getState()
            const checkerboardId = data || checkerboardSlice.checkerboard?.id
            if ( !checkerboardId ) {
                return []
            }

            const sectionList = await api.section.getSectionList({ checkerboardId })
            if ( !sectionSlice.selectedSectionId && sectionList[0]?.id ) {
                dispatch(selectSectionThunk(sectionList[0].id))
            }
            
            return sectionList
        } catch (e) {
            console.log(e)
            throw e
        }
    }
)

export function getSectionListThunkPending(state: RootState['sectionSlice']) {
    state.sectionListIsLoading = !state.sectionList.length
    state.sectionListIsFetching = true
}

export function getSectionListThunkRejected(state: RootState["sectionSlice"]) {
	state.sectionListIsFetching = false
    state.sectionListIsLoading = false
}

export function getSectionListThunkFulfilled(state: RootState["sectionSlice"], { payload }: PayloadAction<SectionListItem[]>) {
    state.sectionList = payload
	state.sectionListIsFetching = false
	state.sectionListIsLoading = false
}