import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state"
import { getApartmentGridFulfilled, getApartmentGridPending, getApartmentGridRejected, getApartmentGridThunk } from "./thunks/get-apartment-grid-thunk"

export const apartmentSlice = createSlice({
    initialState,
    name: "apartmentSlice",
    reducers: {
        resetApartments: () => initialState,
    },
    extraReducers: build => {
        build.addCase(getApartmentGridThunk.pending, getApartmentGridPending)
        build.addCase(getApartmentGridThunk.rejected, getApartmentGridRejected)
        build.addCase(getApartmentGridThunk.fulfilled, getApartmentGridFulfilled)
    }
})

export default apartmentSlice
export { getApartmentGridThunk } from "./thunks/get-apartment-grid-thunk"

export const { resetApartments } = apartmentSlice.actions