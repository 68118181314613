import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@app/api"
import { getFacilityThunk } from 'src/slices/facilitySlice/thunks/getFacilityThunk';
import { AddCheckerboard } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";
import { getCheckerboardThunk } from "./get-checkerboard-thunk";

export const createCheckerboardThunk = createAsyncThunk<void, Omit<AddCheckerboard, "facilityId">, { dispatch: AppDispatch, state: RootState }>(
    "@@checkerboard/create",
    async (data, { dispatch, getState }) => {
        try {
            const { facilitySlice } = getState()
            const facilityId = facilitySlice?.facility?.id
            if ( !facilityId ) {
                throw new Error()
            }
            
            const checkerboardId = await api.checkerboard.add({ ...data, facilityId })
            const text = "Шахматка добавлена"
            dispatch(getFacilityThunk(facilityId))
            dispatch(getCheckerboardThunk(checkerboardId))
			dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function createCheckerboardPending(state: RootState['checkerboardSlice']) {
    state.createCheckerboardInProcess = true
}

export function createCheckerboardRejected(state: RootState["checkerboardSlice"]) {
	state.createCheckerboardInProcess = false
}

export function createCheckerboardFulfilled(state: RootState["checkerboardSlice"]) {
	state.createCheckerboardInProcess = false
}