import {
    FacilityBenefit,
    FacilityFlatDecoration,
    FacilityHouseClass,
    FacilityPaymentOption,
    FacilityRegistration,
    FacilityType
} from "@app/types/facility";
import { ICountryFull } from "./geoGuidTypes";

export type GeoFilterType = {
	countryId?: number
	cityId?: number
	areasIds?: number[]
}

export interface Filter {
	countries: ICountryFull[]
	districts: string[]
	microDistricts: string[]
	facilityTypes: FacilityType[]
	registrations: FacilityRegistration[]
	decorations: FacilityFlatDecoration[]
	paymentOptions: FacilityPaymentOption[]
	houseClasses: FacilityHouseClass[]
	benefits: FacilityBenefit[]
	square?: FilterRange<number>
	price?: FilterRange<number>
	pricePerMeter?: FilterRange<number>
	deliveryDate?: FilterRange<string>
	deliveryDates: DeliveryDate[]
	distanceToTheBeach?: FilterRange<number>
	isDelivered?: boolean
}

export type FilterRange<T> = {
    min: T
    max: T
}

export type DeliveryDate = {
    year: number
    quarter: string
}

export interface FilterParams {
	countryId?: number
	cityId?: number
	areasIds?: string
	districts?: string
	microDistricts?: string
	facilityTypes?: string
	benefits?: string
	registrations?: string
	decorations?: string
	paymentOptions?: string
	houseClasses?: string
	priceGte?: number
	priceLt?: number
	pricePerMeterGte?: number
	pricePerMeterLt?: number
	squareGte?: number
	squareLt?: number
	deliveryDateGte?: string
	deliveryDateLt?: string
	distanceToTheBeachGte?: number
	distanceToTheBeachLt?: number
	deliveryDates?: string
	isDelivered?: boolean
}

export function filterResponseToParams(filters?: Filter & GeoFilterType): FilterParams {
	const params: FilterParams = {}
	if (!filters) return params

	params.benefits = joinArray(filters.benefits)
	params.microDistricts = joinArray(filters.microDistricts)
	params.districts = joinArray(filters.districts)
	params.countryId = filters.countryId
	params.cityId = filters.cityId
	params.areasIds = joinArray(filters.areasIds || [])
	params.registrations = joinArray(filters.registrations)
	params.paymentOptions = joinArray(filters.paymentOptions)
	params.decorations = joinArray(filters.decorations)
	params.facilityTypes = joinArray(filters.facilityTypes)
	params.houseClasses = joinArray(filters.houseClasses)
	params.deliveryDates = parseDeliveryDates(filters.deliveryDates)

	if (filters.price) {
		params.priceGte = filters.price.min
		params.priceLt = filters.price.max
	}
	if (filters.square) {
		params.squareGte = filters.square.min
		params.squareLt = filters.square.max
	}
	if (filters.distanceToTheBeach) {
		params.distanceToTheBeachGte = filters.distanceToTheBeach.min
		params.distanceToTheBeachLt = filters.distanceToTheBeach.max
	}
	if (filters.pricePerMeter) {
		params.pricePerMeterGte = filters.pricePerMeter.min
		params.pricePerMeterLt = filters.pricePerMeter.max
	}
	if (filters.deliveryDate) {
		params.deliveryDateGte = filters.deliveryDate.min
		params.deliveryDateLt = filters.deliveryDate.max
	}

	if (filters.isDelivered) params.isDelivered = true

	return params
}

function joinArray<T>(array: T[]): string | undefined {
    return array.length > 0 ? array.join(',') : undefined
}

function parseDeliveryDates(dates: DeliveryDate[]): string | undefined {
    return dates.length > 0 ? dates.map(d => `${d.year}-${d.quarter}`).join(',') : undefined
}


