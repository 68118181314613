import AppApi from "./index";
import {GetCodeRequest, LoginRequest, LoginResponse} from "../types/auth";
import {Roles, User} from "../types/user";

export default class AuthApi {
    constructor(private api: AppApi) {}

    public getCode(request: GetCodeRequest) {
        return this.api.client.post<GetCodeRequest, boolean>('/auth/getCode', request)
    }

    public login(request: LoginRequest) {
        return this.api.client.post<LoginRequest, LoginResponse>('/auth/login', request)
    }

    public logout() {
        return this.api.client.post<LoginRequest, LoginResponse>('/auth/logout')
    }

    public async me() {
        // if (token) {
        //     this.api.client.defaults.headers.common = { Authorization: `Bearer ${token}` }
        // }
        // const u: User = {name: 'Teeee', role: Roles.ADMIN}
        // return Promise.resolve(u)

        return this.api.client.get<null, User>('/auth/whoami', )
    }
}