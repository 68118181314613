import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from "./initial-state"
import { createRiserThunk, createRiserThunkFulfilled, createRiserThunkPending, createRiserThunkRejected } from "./thunks/create-riser-thunk"
import { getRisersBySectionThunk, getRisersBySectionThunkFulfilled, getRisersBySectionThunkPending, getRisersBySectionThunkRejected } from "./thunks/get-risers-by-section-thunk"
import { updateRiserThunk, updateRiserThunkFulfilled, updateRiserThunkPending, updateRiserThunkRejected } from "./thunks/update-riser-thunk"
import { removeRiserThunk, removeRiserThunkFulfilled, removeRiserThunkPending, removeRiserThunkRejected } from "./thunks/remove-riser-thunk"
import { selectRiserFulfilled, selectRiserThunk } from "./thunks/select-riser-thunk"

const riserSlice = createSlice({
    initialState,
    name: "riserSlice",
    reducers: {
        resetRisers: () => initialState,
        resetSelectedRiser(state) {
            state.selectedRiserId = undefined
        },
    },
    extraReducers: build => {
        build.addCase(createRiserThunk.pending, createRiserThunkPending)
        build.addCase(createRiserThunk.rejected, createRiserThunkRejected)
        build.addCase(createRiserThunk.fulfilled, createRiserThunkFulfilled)

        build.addCase(getRisersBySectionThunk.pending, getRisersBySectionThunkPending)
		build.addCase(getRisersBySectionThunk.rejected, getRisersBySectionThunkRejected)
		build.addCase(getRisersBySectionThunk.fulfilled, getRisersBySectionThunkFulfilled)

        build.addCase(updateRiserThunk.pending, updateRiserThunkPending)
		build.addCase(updateRiserThunk.rejected, updateRiserThunkRejected)
		build.addCase(updateRiserThunk.fulfilled, updateRiserThunkFulfilled)

        build.addCase(removeRiserThunk.pending, removeRiserThunkPending)
		build.addCase(removeRiserThunk.rejected, removeRiserThunkRejected)
		build.addCase(removeRiserThunk.fulfilled, removeRiserThunkFulfilled)

        build.addCase(selectRiserThunk.fulfilled, selectRiserFulfilled)
    }
})

export default riserSlice

export const { resetRisers, resetSelectedRiser } = riserSlice.actions