import { AppDispatch } from 'src/store';
import { AlertProps } from "@mui/material";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertComponentStateType = {
	severity?: AlertProps['severity']
    text?: string
    show: boolean
}

const initialState: AlertComponentStateType = { show: false }

export const alertComponentShow = createAsyncThunk<void, Omit<AlertComponentStateType, 'show'>, { dispatch: AppDispatch }>(
    '@@alertComponentState/show',
    async (args, { dispatch }) => {
        dispatch(alertComponentSlice.actions.alertComponentSet({ show: true, ...args }))
        await new Promise(resolve => setTimeout(resolve, 4000))
    }
)

const alertComponentSlice = createSlice({
	extraReducers: (build) => {
		build.addCase(alertComponentShow.fulfilled, (state) => {
            state.severity = undefined
            state.text = undefined
            state.show = false
        })
	},
	initialState,
	name: "alertComponentState",
	reducers: {
		alertComponentHide: (state) => ({ ...state, show: false }),
		alertComponentSet: (_, { payload }: PayloadAction<AlertComponentStateType>) => payload,
	},
})

export default alertComponentSlice

export const { alertComponentHide } = alertComponentSlice.actions