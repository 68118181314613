import { CheckerboardTabs, PlateboardStateType } from "@app/types/checkerboard";

export const initialState: PlateboardStateType = {
	activeTab: CheckerboardTabs.BASIC,

	cells: [],
	floors: [],
	risers: [],
	selectedCells: [],

	checkerboardIsFetching: false,
	checkerboardIsLoading: false,
	interactive: false,

	createApartmentIsLoading: false,
	deleteApartmentIsLoading: false,
	updateApartmentIsLoading: false,

	createGroupIsLoading: false,
	deleteGroupIsLoading: false,

	addManyFloorsIsLoading: false,
	updateFloorIsLoading: false,
	deleteFloorIsLoading: false,

	addRiserIsLoading: false,
	addManyRisersIsLoading: false,
	deleteRiserIsLoading: false,

	showEditForm: false,
	showFloorDialog: false,
	showRFDialog: false,
	showRiserDialog: false,
}