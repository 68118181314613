import { CountryType } from '@app/types/geoGuidTypes';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getCountryThunk = createAsyncThunk<CountryType, number>("@@countries/get-country", async (countryId) => {
	try {
		return await api.country.getById(countryId)
	} catch (e) {
		console.log(e)
		throw e
	}
})

export function getCountryThunkPending(state: RootState["geoGuidCountriesSlice"]) {
	state.getCountryIsLoading = true
}

export function getCountryThunkRejected(state: RootState["geoGuidCountriesSlice"]) {
	state.getCountryIsLoading = false
}

export function getCountryThunkFullfilled(state: RootState["geoGuidCountriesSlice"], { payload }: PayloadAction<CountryType>) {
	state.getCountryIsLoading = false
	state.country = payload
}