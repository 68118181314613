import AppApi from "./index";
import {Agency, CreateAgencyRequest, UpdateAgencyRequest} from "../types/agency";

export default class AgencyApi {
    constructor(private api: AppApi) {}

    public getAll(): Promise<Agency[]> {
        return this.api.client.get<null, Agency[]>('/agencies')
    }

    public getById(id: number): Promise<Agency> {
        return this.api.client.get<null, Agency>('/agencies/' + id)
    }

    public create(agency: CreateAgencyRequest): Promise<Agency> {
        return this.api.client.post<CreateAgencyRequest, Agency>('/agencies', agency)
    }

    public delete(id: number): Promise<boolean> {
        return this.api.client.delete<null, boolean>('/agencies/' + id)
    }

    public update(id: number, agency: UpdateAgencyRequest): Promise<Agency> {
        return this.api.client.patch<UpdateAgencyRequest, Agency>('/agencies/' + id, agency)
    }
}