import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';
import { alertComponentShow } from 'src/slices/alertComponentSlice';

export const deleteCountryThunk = createAsyncThunk<void, number, { dispatch: AppDispatch }>("@@countries/delete-country", async (countryId, { dispatch }) => {
	try {
		const text = await api.country.delete(countryId)
		dispatch(alertComponentShow({ severity: "success", text }))
	} catch (e) {
		const text = e?.response?.data?.error_message || e?.message || "Что-то пошло не так..."
		dispatch(alertComponentShow({ severity: "error", text }))
		throw e
	}
})

export function deleteCountryThunkPending(state: RootState["geoGuidCountriesSlice"]) {
	state.deleteCountryIsLoading = true
}

export function deleteCountryThunkRejected(state: RootState["geoGuidCountriesSlice"]) {
	state.deleteCountryIsLoading = false
}

export function deleteCountryThunkFullfilled(state: RootState["geoGuidCountriesSlice"]) {
	state.deleteCountryIsLoading = false
	state.deleteCountryIsSuccess = true
	state.showDeleteCountryModal = false
}