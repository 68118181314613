import { PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { range } from 'lodash';

export const addManyRisersThunk = createAsyncThunk<void, { addedRisersCount: number }, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/add-many-risers",
	async ({ addedRisersCount }, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, section: stateSection, risers: stateRisers } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection) {
			return
		}

		const startRiserPossition = Math.max(0, ...stateRisers.map(({ position }) => position)) + 1
		const endRiserPosition = startRiserPossition + addedRisersCount

		const addedRisers = range(startRiserPossition, endRiserPosition).map(position => ({ position }))
		const risers = [...stateRisers, ...addedRisers]

		// замена секции с новыми стояками в массиве секций строения;
		const section = { ...stateSection, risers }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с удалёленным стояком в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function addManyRisersPending(state: PlateboardStateType) {
	state.addManyRisersIsLoading = true
}

export function addManyRisersFulfilled(state: PlateboardStateType) {
	state.addManyRisersIsLoading = false
	state.showRFDialog = false
}

export function addManyRisersRejected(state: PlateboardStateType) {
	state.addManyRisersIsLoading = false
}