import { getAllCountriesThunk } from './getAllCountriesThunk';
import { CreateCountryType } from '@app/types/geoGuidTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';

export const createCountryThunk = createAsyncThunk<void, CreateCountryType, { dispatch: AppDispatch }>("@@countries/create", async (dto, { dispatch }) => {
	try {
		await api.country.createCountry(dto)
		dispatch(getAllCountriesThunk())
	} catch (e) {
		console.log(e)
		throw e
	}
})

export function createCountryThunkPending(state: RootState['geoGuidCountriesSlice']) {
    state.createCountryIsLoading = true
}

export function createCountryThunkRejected(state: RootState["geoGuidCountriesSlice"]) {
	state.createCountryIsLoading = false
}

export function createCountryThunkFullfilled(state: RootState["geoGuidCountriesSlice"]) {
	state.createCountryIsLoading = false
	state.showAddCountryModal = false
}