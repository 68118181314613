import type {FC, ReactNode} from 'react';
import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {authApi} from '../__fake-api__/auth-api';
import type {User} from '@app/types/user';
import {Roles} from "@app/types/user";
import {useAppContext} from "@app/app-context";
import {ErrorApi} from "@app/types/errorApi";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  token?: string | null
  role: Roles
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (token: string, role: Roles) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  switchRole: (role: Roles) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    token?: string | null
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    token?: string
    role: Roles
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type SwitchRoleAction = {
  type: 'SWITCH_ROLE';
  payload: {
    role: Roles
  }
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | SwitchRoleAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
  role: Roles.MANAGER
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, token, role } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token,
      role
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    token: null,
    role: Roles.MANAGER
  }),
  SWITCH_ROLE: (state: State, action: SwitchRoleAction) => {
    // let newRole = Roles.MANAGER
    // if (state.user.role === Roles.ADMIN) {
    //   if (state.role === Roles.MANAGER) newRole = Roles.ADMIN
    //   else newRole = Roles.MANAGER
    // }

    return {
      ...state,
      role: action.payload.role
    }
  },
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  switchRole: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const { api } = useAppContext()
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // const token = localStorage.getItem('authToken')

    api.auth.me()
        .then(res => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: res,
              // token:
            }
          });
        })
        .catch((err: ErrorApi) => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        })
  }, []);

  const login = async (token: string, role: Roles): Promise<void> => {
    localStorage.setItem('authToken', token)
    const user = await api.auth.me();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        token,
        role
      }
    });
  };

  const logout = async (): Promise<void> => {
    await api.auth.logout();
    localStorage.removeItem('authToken');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const switchRole = (role: Roles) => {
    dispatch({
      type: 'SWITCH_ROLE',
      payload: {
        role
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        switchRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
