import { SectionState } from './../../app/types/facilityTypes/sectionTypes/state';

export const initialState: SectionState = {
    createSectionInProcess: false,
    removeSectionInProcess: false,
    sectionList: [],
    sectionListIsFetching: false,
    sectionListIsLoading: false,
    updateSectionInProcess: false,
    createSuccess: false,
}