import AppApi from "./index";
import {Pagination} from "../types/pagination";
import {
    CreateFacilityRequest,
    Facility,
    GetFacilitiesParams,
    GetFacilitiesWithFilters,
    UpdateFacilityRequest
} from "../types/facility";

export default class FacilityApi {
    constructor(private api: AppApi) {}

    public getAll(params: GetFacilitiesWithFilters): Promise<Pagination<Facility[]>> {
        return this.api.client.get<null, Pagination<Facility[]>>('/v2/facilities', { params: { showDisabled: true, ...params } })
    }

    public getById(id: number): Promise<Facility> {
        return this.api.client.get<null, Facility>('/facilities/' + id)
    }

    public create(request: CreateFacilityRequest): Promise<Facility> {
        return this.api.client.post<CreateFacilityRequest, Facility>('/facilities', request)
    }

    public delete(id: number): Promise<boolean> {
        return this.api.client.delete<null, boolean>('/facilities/' + id)
    }

    public update(id: number, request: UpdateFacilityRequest): Promise<Facility> {
        return this.api.client.patch<UpdateFacilityRequest, Facility>('/facilities/' + id, request)
    }
}
