import { api } from "@app/api";
import { IHouse } from "@app/types/houseTypes";
import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store"

export const uploadPhotoThunk = createAsyncThunk<IHouse, File, { dispatch: AppDispatch, state: RootState }>(
    "@@house/upload-photos",
    async (file, { dispatch, getState }) => {
        try {
            const state = getState()
			const { selectedHouse } = state.houseSlice
			if (!selectedHouse) {
				throw new Error("Дом не определён")
			}

            const { id, photoNames, photos } = selectedHouse
			const { filename } = await api.file.uploadImage(file)
			return await api.house.update({ id, photos: [...photos, filename], photoNames: [...photoNames, ""] })
        } catch (e) {
            console.warn(e)
            const text = e?.response?.data?.error_message || e?.message || "Ошибка загрузки изображения..."
			dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function uploadPhotoPending(state: RootState['houseSlice']) {
    state.uploadPhotoIsLoading = true
}

export function uploadPhotoRejected(state: RootState["houseSlice"]) {
	state.uploadPhotoIsLoading = false
}

export function uploadPhotoFulfilled(state: RootState["houseSlice"], { payload }: PayloadAction<IHouse>) {
    const index = state.houses.findIndex(({ id }) => id === payload.id)
    if (index !== -1) {
		state.houses[index] = payload
	}
	state.selectedHouse = payload
	state.uploadPhotoIsLoading = false
}