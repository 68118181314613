import { FacilityFlatDecoration, RoomCount } from "@app/types/facility";

export enum FloorType {
    Residential = "Жилой",
    Mansarda = "Мансарда",
    Commercial = "Коммерческий",
    Parking = "Парковка",
    Minus = "Минус",
    Ground = "Цоколь",
}

export interface Checkerboard {
    id: number;
    buildingCount: number;
    minApartmentSquare: number;
    maxApartmentSquare: number;
    minApartmentPrice: number;
    maxApartmentPrice: number;
    apartmentNumberMin: number;
    apartmentNumberMax: number;
    buildings: Building[];
}

export interface CreateCheckerboardDto {
    buildings: CreateBuildingDto[];
    minApartmentSquare: number;
    maxApartmentSquare: number;
    minApartmentPrice: number;
    maxApartmentPrice: number;
}

export interface CreateBuildingDto {
    id?: number;
    deliveryDate: string;
    name: string;
    minApartmentNumber: string;
    maxApartmentNumber: string;
    sections: CreateSectionDto[];
}

export interface UpdateBuildingDto extends CreateBuildingDto {
    id: number;
}

export interface UpdateCheckerboardDto extends CreateCheckerboardDto {
    buildings: UpdateBuildingDto[];
}

export interface Building {
    id: number;
    sections: Section[];
    floorCount: number;
    riserCount: number;
    sectionCount: number;
    deliveryDate: string;
    name: string;
    minApartmentNumber: string;
    maxApartmentNumber: string;
}

export interface Section {
    id: number;
    name: string;
    deliveryDate: string;
    floorLayouts: string[];
    risers?: Riser[];
    floors?: Floor[];
    apartmentLinks?: ApartmentLink[];
}

export interface CreateSectionDto {
    id?: number;
    name: string;
    deliveryDate: string;
    buildingId?: number;
    floorLayouts: string[];
    risers?: CreateRiserDto[];
    floors?: CreateFloorDto[];
}

export interface UpdateSectionDto extends Partial<CreateSectionDto> {}

export interface ApartmentLink {
    sectionId: number;
    riserId: number;
    floorId: number;
    apartment?: Apartment;
}

export interface CreateApartmentLinkDto {
    sectionId: number;
    riserId: number;
    floorId: number;
    apartment?: CreateApartmentDto;
}

export interface UpdateApartmentLinkDto extends Partial<CreateApartmentLinkDto> {}

export interface Riser {
    id: number;
    position: number;
}

export interface CreateRiserDto {
    sectionId?: number;
    position: number;
}

export interface UpdateRiserDto extends Partial<CreateRiserDto> {}

export interface Floor {
	id?: number
	floorLayout?: string | null
	name: string
	sort_order: number
	isDuplex: boolean
	type: FloorType
}

export interface CreateFloorDto {
    sectionId?: number;
    name: string;
    sort_order: number;
    isDuplex: boolean;
    type: FloorType;
}

export interface UpdateFloorDto extends Partial<CreateFloorDto> {}

export interface Apartment {
    id: number;
    isEnabled: boolean;
    square: number;
    squarePrice: number;
    price: number;
    number: string;
    hasCession: boolean;
    sales: string[];
    decoration: FacilityFlatDecoration;
    floorLayout: string;
    floorLayoutOutline: string | null;
    floorLayoutPath: string | null;
    layout: string;
    isBooked?: boolean;
    isReserved?: boolean;
    isHidden?: boolean;
    groupId?: number;
    floorStart?: number;
    floorEnd?: number;
    riserStart?: number;
    riserEnd?: number;
    isDuplex?: boolean;
    isSold?: boolean
    roomCount: RoomCount;
}

export interface CreateApartmentDto {
    isEnabled: boolean;
    square: number;
    squarePrice: number;
    price: number;
    number: string;
    hasCession: boolean;
    sales: string[];
    decoration: FacilityFlatDecoration;
    floorLayout?: string;
    layout?: string;
    isBooked: boolean;
    isReserved: boolean;
    isHidden: boolean;
    roomCount: RoomCount;
    floorStart?: number;
    floorEnd?: number;
    riserStart?: number;
    riserEnd?: number;
    isDuplex?: boolean;
}

export interface UpdateApartmentDto extends Partial<CreateApartmentDto> {
    isSold?: boolean
}

export type SetApartmentLayoutPathDto = {
	id: number;
    floorLayoutOutline: string;
    floorLayoutPath: string;
}

export type SetFloorLayoutPathDto = {
	id: number
	floorLayout: string
}

export type Cell = {
    riser: number;
    floor: number;
};

export type Group = {
    groupId?: number;
    section: number;
    apartment?: Apartment;
    link?: ApartmentLink;
    cells: Cell[];
};

export enum CheckerboardTabs {
    BASIC,
    ADVANCED,
}

export interface GroupDtoCell {
    floorId: number;
    riserId: number;
}

export interface CreateGroupDto {
    sectionId: number;
    cells: GroupDtoCell[];
}

export interface CreateGroupResponse {
    id: number;
}

export interface CheckerboardFilters {
    decorations: FacilityFlatDecoration[];
    deliveryDates: string[];
    pricePerMeterLte?: number;
    pricePerMeterGte?: number;
    priceLte?: number;
    priceGte?: number;
    squareLte?: number;
    squareGte?: number;
}

export interface CheckerboardFiltersParams {
    decorations?: string;
    deliveryDates?: string;
    pricePerMeterLte?: number;
    pricePerMeterGte?: number;
    priceLte?: number;
    priceGte?: number;
    squareLte?: number;
    squareGte?: number;
}

export function filterResponseToParams(filters?: Partial<CheckerboardFilters>): CheckerboardFiltersParams {
    const params: CheckerboardFiltersParams = {};
    if (!filters) return params;

    params.deliveryDates = joinArray(filters.deliveryDates);
    params.decorations = joinArray(filters.decorations);
    params.priceLte = filters.priceLte;
    params.priceGte = filters.priceGte;
    params.squareLte = filters.squareLte;
    params.squareGte = filters.squareGte;
    params.pricePerMeterLte = filters.pricePerMeterLte;
    params.pricePerMeterGte = filters.pricePerMeterGte;

    return params;
}

function joinArray<T>(array?: T[]): string | undefined {
    if (!array) return undefined;
    return array.length > 0 ? array.join(",") : undefined;
}

export type CellPositionType = {
    top: number
    left: number
    bottom?: number
    right?: number
}

export type PlateboardCellType = {
    id: string
	apartment?: Apartment
	cellPosition: CellPositionType
    floorId?: string
    riserId?: string
}

export type PlateboardStateType = {
	activeTab: CheckerboardTabs
	checkerboard?: Checkerboard
	interactive: boolean
	building?: Building
	section?: Section
	floors: Floor[]
	risers: Riser[]
	selectedFloor?: Floor
	selectedRiser?: Riser
	cells: PlateboardCellType[]
	selectedCells: PlateboardCellType[]
	showEditForm: boolean
	showFloorDialog: boolean
	showRiserDialog: boolean
	showRFDialog: boolean
	checkerboardIsLoading: boolean
	checkerboardIsFetching: boolean
	createApartmentIsLoading: boolean
	updateApartmentIsLoading: boolean
	deleteApartmentIsLoading: boolean
	createGroupIsLoading: boolean
	deleteGroupIsLoading: boolean
	addRiserIsLoading: boolean
	deleteRiserIsLoading: boolean
	addManyFloorsIsLoading: boolean
	addManyRisersIsLoading: boolean
	updateFloorIsLoading: boolean
	deleteFloorIsLoading: boolean
}