import { Floor, FloorType, PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Возвращает новый набор этажей;
 * @param floors 
 * @param selectedFloor 
 */
function getFloors(floors: Floor[], selectedFloor: Floor) {
	const filteredFloors = [...floors].reverse().filter(({ id }) => id !== selectedFloor.id)

	if ( ![FloorType.Minus, FloorType.Residential].includes(selectedFloor.type) ) {
		return filteredFloors
	}

	const minusFloors = filteredFloors.filter(({ type }) => type === FloorType.Minus)
	const nextFloorMinus = filteredFloors.length === 1 ? -1 : -minusFloors.length
	const nextFloor = selectedFloor.type === FloorType.Residential ? 1 : nextFloorMinus

	return filteredFloors.reduce<{ res: Floor[]; nextFloor: number }>(
		({ nextFloor, res }, item, sort_order) => {
			const name = item.type === FloorType.Residential ? String(nextFloor++) : item.name
			const newFloor: Floor = { ...item, sort_order, name }
			res.push(newFloor)
			return { nextFloor, res }
		},
		{ res: [], nextFloor }
	).res
}

export const deleteFloorThunk = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/delete-floor",
	async (_, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, floors: stateFloors, section: stateSection, selectedFloor } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection || !selectedFloor) {
			return
		}

		// удаление этажа из массива имеющихся;
		const floors = getFloors(stateFloors, selectedFloor)

		// замена секции с удалёленным стояком в массиве секций строения;
		const section = { ...stateSection, floors }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с удалёленным стояком в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function deleteFloorPending(state: PlateboardStateType) {
	state.deleteFloorIsLoading = true
}

export function deleteFloorFulfilled(state: PlateboardStateType) {
	state.deleteFloorIsLoading = false
	state.showFloorDialog = false
}

export function deleteFloorRejected(state: PlateboardStateType) {
	state.deleteFloorIsLoading = false
}