import { createAsyncThunk } from "@reduxjs/toolkit"
import { PlateboardStateType } from "@app/types/checkerboard"
import { api } from "@app/api"

type DeleteApartmentParams = {
	checkerboardId: number
	apartmentId: number
}

export const deleteApartmentThunk = createAsyncThunk("@@checkerboard/delete-apartment", ({ checkerboardId, apartmentId }: DeleteApartmentParams) => {
	return api.checkerboard.deleteApartment(checkerboardId, apartmentId)
})

export function deleteApartmentPending(state: PlateboardStateType) {
	state.deleteApartmentIsLoading = true
}

export function deleteApartmentFulfilled(state: PlateboardStateType) {
	state.deleteApartmentIsLoading = false
}

export function deleteApartmentRejected(state: PlateboardStateType) {
	state.deleteApartmentIsLoading = false
}