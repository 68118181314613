import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialState } from "./initial-state"
import { createSectionThunk, createSectionThunkFulfilled, createSectionThunkPending, createSectionThunkRejected } from "./thunks/create-section-thunk"
import { getSectionListThunk, getSectionListThunkFulfilled, getSectionListThunkPending, getSectionListThunkRejected } from "./thunks/get-section-list-thunk"
import { updateSectionThunk, updateSectionThunkFulfilled, updateSectionThunkPending, updateSectionThunkRejected } from "./thunks/update-section-thunk"
import { removeSectionThunk, removeSectionThunkFulfilled, removeSectionThunkPending, removeSectionThunkRejected } from "./thunks/remove-section-thunk"
import { selectSectionFulfilled, selectSectionThunk } from "./thunks/select-section-thunk"

const sectionSlice = createSlice({
    initialState,
    name: 'sectionSlice',
    reducers: {
        resetCreateSuccess(state) {
            state.createSuccess = false
        },
        resetSectionSlice: () => initialState,
        setSelectedSectionId(state, { payload }: PayloadAction<number | undefined>) {
            state.selectedSectionId = payload
        }
    },
    extraReducers: build => {
        build.addCase(createSectionThunk.pending, createSectionThunkPending)
        build.addCase(createSectionThunk.rejected, createSectionThunkRejected)
        build.addCase(createSectionThunk.fulfilled, createSectionThunkFulfilled)

        build.addCase(getSectionListThunk.pending, getSectionListThunkPending)
		build.addCase(getSectionListThunk.rejected, getSectionListThunkRejected)
		build.addCase(getSectionListThunk.fulfilled, getSectionListThunkFulfilled)

        build.addCase(updateSectionThunk.pending, updateSectionThunkPending)
		build.addCase(updateSectionThunk.rejected, updateSectionThunkRejected)
		build.addCase(updateSectionThunk.fulfilled, updateSectionThunkFulfilled)

        build.addCase(removeSectionThunk.pending, removeSectionThunkPending)
		build.addCase(removeSectionThunk.rejected, removeSectionThunkRejected)
		build.addCase(removeSectionThunk.fulfilled, removeSectionThunkFulfilled)
        
        build.addCase(selectSectionThunk.fulfilled, selectSectionFulfilled)
    }
})

export default sectionSlice

export const { resetCreateSuccess, resetSectionSlice, setSelectedSectionId } = sectionSlice.actions