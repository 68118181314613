import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialState } from "./initial-state"
import { IBuilding } from "@app/types/facilityTypes"
import { createBuildingFulfilled, createBuildingPending, createBuildingRejected, createBuildingThunk } from "./thunks/create-building-thunk"
import {
	getBuildingsByCheckerboardFulfilled,
	getBuildingsByCheckerboardPending,
	getBuildingsByCheckerboardRejected,
	getBuildingsByCheckerboardThunk,
} from "./thunks/get-buildings-by-checkerboard-thunk"
import { removeBuildingFulfilled, removeBuildingPending, removeBuildingRejected, removeBuildingThunk } from "./thunks/remove-building-thunk"
import { updateBuildingFulfilled, updateBuildingPending, updateBuildingRejected, updateBuildingThunk } from "./thunks/update-building-thunk"
export { createBuildingThunk } from "./thunks/create-building-thunk"
export { getBuildingsByCheckerboardThunk } from "./thunks/get-buildings-by-checkerboard-thunk"
export { removeBuildingThunk } from "./thunks/remove-building-thunk"
export { updateBuildingThunk } from "./thunks/update-building-thunk"

const buildingSlice = createSlice({
	initialState,
	name: "buildingSlice",
	reducers: {
		resetBuildings: () => initialState,
		resetCreateSuccess(state) {
			state.createSuccess = false
		},
        setBuildings(state, { payload }: PayloadAction<IBuilding[]>) {
            state.buildings = payload
        },
		toggleCheckBuildingById(state, { payload }: PayloadAction<number>) {
			if (state.selectedBuilding?.id === payload) {
				state.selectedBuilding = undefined
				return
			}
			const building = state.buildings.find(({ id }) => id === payload)
			state.selectedBuilding = building
		},
	},
	extraReducers: (build) => {
		build.addCase(createBuildingThunk.pending, createBuildingPending)
		build.addCase(createBuildingThunk.rejected, createBuildingRejected)
		build.addCase(createBuildingThunk.fulfilled, createBuildingFulfilled)

        build.addCase(getBuildingsByCheckerboardThunk.pending, getBuildingsByCheckerboardPending)
		build.addCase(getBuildingsByCheckerboardThunk.rejected, getBuildingsByCheckerboardRejected)
		build.addCase(getBuildingsByCheckerboardThunk.fulfilled, getBuildingsByCheckerboardFulfilled)

        build.addCase(removeBuildingThunk.pending, removeBuildingPending)
		build.addCase(removeBuildingThunk.rejected, removeBuildingRejected)
		build.addCase(removeBuildingThunk.fulfilled, removeBuildingFulfilled)

        build.addCase(updateBuildingThunk.pending, updateBuildingPending)
		build.addCase(updateBuildingThunk.rejected, updateBuildingRejected)
		build.addCase(updateBuildingThunk.fulfilled, updateBuildingFulfilled)
	},
})

export default buildingSlice
export const { resetBuildings, resetCreateSuccess, setBuildings, toggleCheckBuildingById } = buildingSlice.actions
