import { getPlateboardThunk } from './getPlateboardThunk';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { PlateboardStateType } from "@app/types/checkerboard"
import { api } from "@app/api"
import { AppDispatch, RootState } from "src/store"

export const deleteGroupThunk = createAsyncThunk<void, void, { dispatch: AppDispatch, state: RootState }>("@@checkerboard/delete-group", async (_, thunkApi) => {
	const { dispatch, getState } = thunkApi
	const state = getState()
	const checkerboardId = state.plateboard.checkerboard?.id
	const groupId = state.plateboard.selectedCells[0]?.apartment?.groupId
	if (!checkerboardId || !groupId) {
		return
	}

	await api.checkerboard.deleteGroup(checkerboardId, groupId)
	dispatch(getPlateboardThunk({ checkerboardId }))
})

export function deleteGroupPending(state: PlateboardStateType) {
	state.deleteGroupIsLoading = true
}

export function deleteGroupFulfilled(state: PlateboardStateType) {
	state.deleteGroupIsLoading = false
}

export function deleteGroupRejected(state: PlateboardStateType) {
	state.deleteGroupIsLoading = false
}