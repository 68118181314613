import { api } from "@app/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";
import { getBuildingsByCheckerboardThunk } from "./get-buildings-by-checkerboard-thunk";

export const removeBuildingThunk = createAsyncThunk<void, number | undefined, { dispatch: AppDispatch, state: RootState }>(
    "@@building/remove",
    async (buildingId, { dispatch, getState }) => {
        try {
            const { buildingSlice } = getState()
            const id = buildingId || buildingSlice.selectedBuilding?.id
            if ( !id ) {
                dispatch(alertComponentShow({ severity: "error", text: "Строение не определено" }))
            }
            const text = await api.building.delete({ id })
            dispatch(getBuildingsByCheckerboardThunk(id))
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function removeBuildingPending(state: RootState['buildingSlice']) {
    state.removeBuildingInProcess = true
}

export function removeBuildingRejected(state: RootState["buildingSlice"]) {
	state.removeBuildingInProcess = false
}

export function removeBuildingFulfilled(state: RootState["buildingSlice"]) {
	state.removeBuildingInProcess = false
}