import { houses } from "@app/api/endpoints";
import AppApi from "@app/api/index";
import { CreateHouse, IHouse, IHouseUpdate } from "@app/types/houseTypes/dtoTypes";

export default class HouseApi {
	constructor(private readonly api: AppApi) {}

	public create(dto: CreateHouse) {
		return this.api.client.post<CreateHouse, string>(houses.create(), dto)
	}

	public getAll(facilityId: number) {
		return this.api.client.get<void, IHouse[]>(houses.getAll(facilityId))
	}

	public getById(houseId: number) {
		return this.api.client.get<number, IHouse>(houses.getById(houseId))
	}

	public update(data: IHouseUpdate) {
		return this.api.client.put<IHouseUpdate, IHouse>(houses.update(), data)
	}

	public delete(houseId: number) {
		return this.api.client.delete<string, string>(houses.delete(houseId))
	}
}