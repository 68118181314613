import { FloorType, PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';
import renameFloors from '../helpers/renameFloors';

export const updateFloorThunk = createAsyncThunk<void, { isDuplex: boolean; type: FloorType }, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/update-floor",
	async ({ isDuplex, type }, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, floors: stateFloors, section: stateSection, selectedFloor } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection || !selectedFloor) {
			return
		}

		// изменение этажа в массиве имеющихся;
		let requiredRename = false
		let floors = [...stateFloors].map((item) => {
			if (item.id === selectedFloor.id) {
				if (type !== undefined && item.type !== type) requiredRename = true
				const nextData = { ...item, isDuplex, type }
				return nextData
			}
			return item
		})
		if (requiredRename) floors = renameFloors([...floors])

		// замена секции с измененным этажом в массиве секций строения;
		const section = { ...stateSection, floors }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с измененным этажом в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function updateFloorPending(state: PlateboardStateType) {
	state.updateFloorIsLoading = true
}

export function updateFloorFulfilled(state: PlateboardStateType) {
	state.updateFloorIsLoading = false
	state.showFloorDialog = false
}

export function updateFloorRejected(state: PlateboardStateType) {
	state.updateFloorIsLoading = false
}