import { getAllAreasThunk } from "./getAllAreasThunk"
import { CreateAreaType } from "@app/types/geoGuidTypes"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';

export const createAreaThunk = createAsyncThunk<void, { title: string }, { dispatch: AppDispatch, state: RootState }>(
	"@@cities/create",
	async ({ title }, { dispatch, getState }) => {
		const state = getState()
		const cityId = state.geoGuidCitiesSlice.city?.id
		if ( !cityId ) {
			throw new Error('Не удалось определить город')
		}
		await api.area.createArea({ cityId, title })
		dispatch(getAllAreasThunk())
	}
)

export function createAreaThunkPending(state: RootState['geoGuidAreasSlice']) {
    state.createAreaIsLoading = true
}

export function createAreaThunkRejected(state: RootState["geoGuidAreasSlice"]) {
	state.createAreaIsLoading = false
}

export function createAreaThunkFullfilled(state: RootState["geoGuidAreasSlice"]) {
	state.createAreaIsLoading = false
	state.showAddAreaModal = false
}