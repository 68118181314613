import { Checkerboard } from "."

export enum CheckerboardMainInfoTab {
	BASIC,
	BUILDS_AND_SECTIONS,
}

export type CheckerboardState = {
	checkerboard?: Checkerboard
	checkerboardIsLoading: boolean
	checkerboardIsFetching: boolean
	createCheckerboardInProcess: boolean
	mainInfoActiveTab: CheckerboardMainInfoTab
	updateCheckerboardInProcess: boolean
	removeCheckerboardInProcess: boolean
}