import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState"
import { IHouse, RangedHouseFields, SelectedHouseFields } from '@app/types/houseTypes';

function notEmpty<T>(value: T|undefined|null): value is T {
	return !!value
}

function getRange(arr: IHouse[], field: keyof RangedHouseFields) {
	const items = arr.map((item) => item[field]).filter(notEmpty)
	if (items.length > 1) {
		const minRange = Math.min(...items)
		const maxRange = Math.max(...items)
		return { maxRange, minRange }
	}
}

function getCheckSet(arr: IHouse[], field: keyof SelectedHouseFields) {
	return Array.from(new Set(arr.map(item => item[field])))
}

const houseFitlerSlice = createSlice({
	initialState,
	name: "houseFilter",
	reducers: {
		initHouseFilter(state, { payload }) {
			const priceRange = getRange(payload, "price")
			const squareRange = getRange(payload, "square")
			const squarePirceRange = getRange(payload, "squarePrice")

			state.priceFilter.minRange = priceRange?.minRange
			state.priceFilter.maxRange = priceRange?.maxRange
			state.priceFilter.min = priceRange?.minRange
			state.priceFilter.max = priceRange?.maxRange

			state.pricePerMeterFilter.minRange = squarePirceRange?.minRange
			state.pricePerMeterFilter.maxRange = squarePirceRange?.maxRange
			state.pricePerMeterFilter.min = squarePirceRange?.minRange
			state.pricePerMeterFilter.max = squarePirceRange?.maxRange

			state.squareFilter.minRange = squareRange?.minRange
			state.squareFilter.maxRange = squareRange?.maxRange
			state.squareFilter.min = squareRange?.minRange
			state.squareFilter.max = squareRange?.maxRange

			state.decorationFilter.values = getCheckSet(payload, "decoration")
		},
		resetHouseFilters: () => initialState,
		setCheckboxFilter(state, { payload }) {
			const index = Object.values(state).findIndex((item) => item["field"] === payload.field)
			const key = Object.keys(state)[index]
			const selected = state[key]?.selected
			if ( !selected ) {
				throw new Error(`Фильтр ${payload.field} не найден`)
			}
			const valueIndex = selected.findIndex(item => item === payload.value)
			if ( valueIndex === -1 ) {
				selected.push(payload.value)
			} else {
				selected.splice(index, 1)
			}
		},
		setRangeFilter(state, { payload }) {
			const index = Object.values(state).findIndex((item) => item["field"] === payload.field)
			const key = Object.keys(state)[index]
			if (state[key]?.minRange > payload.min || state[key]?.maxRange < payload.min) {
				throw new Error(`Min вне диапазона`)
			}
			if (state[key]?.minRange > payload.max || state[key]?.maxRange < payload.max) {
				throw new Error(`Max вне диапазона`)
			}
			if (payload.min > payload.max) {
				throw new Error(`Max меньше min`)
			}
			state[key].min = payload.min
			state[key].max = payload.max
		},
	},
})

export default houseFitlerSlice

export const { initHouseFilter, resetHouseFilters, setCheckboxFilter, setRangeFilter } = houseFitlerSlice.actions