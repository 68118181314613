import { api } from '@app/api';
import { IHouse, IHouseUpdate } from '@app/types/houseTypes';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { alertComponentShow } from "../../alertComponentSlice"
import { setSelectedHouse } from '../houseSlice';

export const updateHouseThunk = createAsyncThunk<IHouse, Omit<IHouseUpdate, 'id'>, { dispatch: AppDispatch; state: RootState }>(
	"@@houses/update",
	async (data, { dispatch, getState }) => {
		const state = getState()
		const id = state.houseSlice.selectedHouse?.id
		const { houseIsLoading } = state.houseSlice
        try {
            if (!id || houseIsLoading) {
				throw new Error("Дом не определён")
			}
            const result = await api.house.update({ ...data, id })
            const text = "Информация изменена"
		    dispatch(alertComponentShow({ text, severity: 'success' }))
            dispatch(setSelectedHouse(undefined))
            return result
        } catch(e: any) {
            const text = e?.response?.data?.error_message || e?.message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
	}
)

export function updateHousePending(state: RootState['houseSlice']) {
    state.updateHouseIsLoading = true
}

export function updateHouseRejected(state: RootState["houseSlice"]) {
	state.updateHouseIsLoading = false
}

export function updateHouseFulfilled(state: RootState['houseSlice'], { payload }: PayloadAction<IHouse>) {
    const index = state.houses.findIndex(({ id }) => id === payload.id)
    if ( index !== -1 ) {
        state.houses[index] = payload
    }
    state.selectedHouse = payload
    state.updateHouseIsLoading = false
    state.updateHouseIsSuccess = true
}
