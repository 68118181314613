import { createAsyncThunk } from "@reduxjs/toolkit"
import { api } from "@app/api"
import { UpdateBuilding } from "@app/types/facilityTypes"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { AppDispatch, RootState } from "src/store"
import { getBuildingsByCheckerboardThunk } from "./get-buildings-by-checkerboard-thunk"

export const updateBuildingThunk = createAsyncThunk<void, UpdateBuilding, { dispatch: AppDispatch; state: RootState }>(
	"@@building/update",
	async (data, { dispatch }) => {
		try {
			const text = await api.building.update(data)
			dispatch(getBuildingsByCheckerboardThunk(undefined))
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function updateBuildingPending(state: RootState["buildingSlice"]) {
	state.updateBuildingInProcess = true
}

export function updateBuildingRejected(state: RootState["buildingSlice"]) {
	state.updateBuildingInProcess = false
}

export function updateBuildingFulfilled(state: RootState["buildingSlice"]) {
	state.updateBuildingInProcess = false
}
