import { renameAreaThunk, renameAreaThunkFullfilled, renameAreaThunkPending, renameAreaThunkRejected } from './thunks/renameAreaThunk';
import { getAreaThunk, getAreaThunkFullfilled, getAreaThunkPending, getAreaThunkRejected } from './thunks/getAreaThunk';
import { getAllAreasThunk, getAllAreasThunkFullfilled, getAllAreasThunkPending, getAllAreasThunkRejected } from './thunks/getAllAreasThunk';
import { createAreaThunk, createAreaThunkFullfilled, createAreaThunkPending, createAreaThunkRejected } from './thunks/createAreaThunk';
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GeoGuidAreasStateType } from "@app/types/geoGuidTypes"
import { deleteAreaThunk, deleteAreaThunkFullfilled, deleteAreaThunkPending, deleteAreaThunkRejected } from './thunks/deleteAreaThunk';

const initialState: GeoGuidAreasStateType = {
	areas: [],
	createAreaIsLoading: false,
	areasIsLoading: false,
	getAreaIsLoading: false,
	renameAreaIsLoading: false,
	deleteAreaIsLoading: false,
	deleteAreaIsSuccess: false,
	showAddAreaModal: false,
	showDeleteAreaModal: false,
	showRenameAreaModal: false,
}

const geoGuidAreasSlice = createSlice({
	extraReducers: (build) => {
		build.addCase(createAreaThunk.pending, createAreaThunkPending)
		build.addCase(createAreaThunk.rejected, createAreaThunkRejected)
		build.addCase(createAreaThunk.fulfilled, createAreaThunkFullfilled)

		build.addCase(getAllAreasThunk.pending, getAllAreasThunkPending)
		build.addCase(getAllAreasThunk.rejected, getAllAreasThunkRejected)
		build.addCase(getAllAreasThunk.fulfilled, getAllAreasThunkFullfilled)

		build.addCase(getAreaThunk.pending, getAreaThunkPending)
		build.addCase(getAreaThunk.rejected, getAreaThunkRejected)
		build.addCase(getAreaThunk.fulfilled, getAreaThunkFullfilled)

		build.addCase(renameAreaThunk.pending, renameAreaThunkPending)
		build.addCase(renameAreaThunk.rejected, renameAreaThunkRejected)
		build.addCase(renameAreaThunk.fulfilled, renameAreaThunkFullfilled)

		build.addCase(deleteAreaThunk.pending, deleteAreaThunkPending)
		build.addCase(deleteAreaThunk.rejected, deleteAreaThunkRejected)
		build.addCase(deleteAreaThunk.fulfilled, deleteAreaThunkFullfilled)
	},
	initialState,
	name: "geoGuidAreasSlice",
	reducers: {
		resetArea(state) {
			state.area = undefined
		},
		resetDeleteAreaIsSuccess(state) {
			state.deleteAreaIsSuccess = false
		},
		setShowAddAreaModal(state, { payload }: PayloadAction<boolean>) {
			state.showAddAreaModal = payload
		},
		setShowDeleteAreaModal(state, { payload }: PayloadAction<boolean>) {
			state.showDeleteAreaModal = payload
		},
		setShowRenameAreaModal(state, { payload }: PayloadAction<boolean>) {
			state.showRenameAreaModal = payload
		},
	},
})

export default geoGuidAreasSlice

export const { resetArea, resetDeleteAreaIsSuccess, setShowAddAreaModal, setShowDeleteAreaModal, setShowRenameAreaModal } = geoGuidAreasSlice.actions
