import {combineReducers} from '@reduxjs/toolkit';
import {reducer as filters} from "../slices/filters";
import {reducer as announcements} from "../slices/announcement";
import {reducer as checkerboardFilters} from '../slices/checkerboard-filters';
import {reducer as checkerboard} from '../slices/checkerboard';
import plateboardSlice from '../slices/plateboard/plateboardSlice'
import alertComponentSlice from 'src/slices/alertComponentSlice';
import geoGuidCitiesSlice from "src/slices/geoGuidCitiesSlice"
import geoGuidCountriesSlice from 'src/slices/geoGuidCountriesSlice';
import geoGuidAreasSlice from 'src/slices/geoGuidAreasSlice';
import houseFitlerSlice from 'src/slices/houseFilterSlice';
import houseSlice from 'src/slices/houseSlice/houseSlice';
import facilitySlice from 'src/slices/facilitySlice';
import apartmentSlice from 'src/slices/apartment-slice';
import checkerboardSlice from 'src/slices/checkerboard-slice';
import floorSlice from 'src/slices/floor-slice';
import riserSlice from 'src/slices/riser-slice';
import sectionSlice from 'src/slices/section-slice';
import buildingSlice from 'src/slices/building-slice';

export const rootReducer = combineReducers({
	filters,
	announcements,
	checkerboardFilters,
	checkerboard,
	[alertComponentSlice.name]: alertComponentSlice.reducer,
	[apartmentSlice.name]: apartmentSlice.reducer,
	[buildingSlice.name]: buildingSlice.reducer,
	[checkerboardSlice.name]: checkerboardSlice.reducer,
	[facilitySlice.name]: facilitySlice.reducer,
	[floorSlice.name]: floorSlice.reducer,
	[geoGuidAreasSlice.name]: geoGuidAreasSlice.reducer,
	[geoGuidCitiesSlice.name]: geoGuidCitiesSlice.reducer,
	[geoGuidCountriesSlice.name]: geoGuidCountriesSlice.reducer,
	[houseFitlerSlice.name]: houseFitlerSlice.reducer,
	[houseSlice.name]: houseSlice.reducer,
	[plateboardSlice.name]: plateboardSlice.reducer,
	[riserSlice.name]: riserSlice.reducer,
	[sectionSlice.name]: sectionSlice.reducer,
})
