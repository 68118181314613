import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { IBuilding } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";
import { getSectionListThunk } from "src/slices/section-slice/thunks/get-section-list-thunk";

export const getBuildingsByCheckerboardThunk = createAsyncThunk<IBuilding[], number, { dispatch: AppDispatch, state: RootState }>(
    "@@building/get-by-checkerboard",
    async (id, { dispatch, getState }) => {
        try {
            const { checkerboardSlice } = getState()
            const checkerboardId = id || checkerboardSlice.checkerboard?.id
            if ( !checkerboardId ) {
                dispatch(alertComponentShow({ severity: "error", text: "Шахматка не определена" }))
                return
            }
            dispatch(getSectionListThunk(checkerboardId))
            return await api.building.getByCheckerboard({ checkerboardId })
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function getBuildingsByCheckerboardPending(state: RootState["buildingSlice"]) {
	state.buildingsIsLoading = !state.buildings.length
    state.buildingsIsFetching = true
}

export function getBuildingsByCheckerboardRejected(state: RootState["buildingSlice"]) {
	state.buildingsIsFetching = false
    state.buildingsIsLoading = false
}

export function getBuildingsByCheckerboardFulfilled(state: RootState["buildingSlice"], { payload }: PayloadAction<IBuilding[]>) {
    state.buildings = payload
    state.buildingsIsFetching = false
	state.buildingsIsLoading = false
}