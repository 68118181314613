import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { createHouseFulfelled, createHousePending, createHouseRejected, createHouseThunk } from "./thunks/createHouseThunk"
import { getHousesFulfilled, getHousesPending, getHousesRejected, getHousesThunk } from './thunks/getHousesThunk';
import { getOneHouseFulfilled, getOneHousePending, getOneHouseRejected, getOneHouseThunk } from './thunks/getOneHouseThunk';
import { removePhotoFulfilled, removePhotoThunk } from './thunks/removePhotoThunk';
import { updateHouseFulfilled, updateHousePending, updateHouseRejected, updateHouseThunk } from "./thunks/updateHouseThunk"
import { uploadPhotoFulfilled, uploadPhotoPending, uploadPhotoRejected, uploadPhotoThunk } from './thunks/uploadPhotoThunk';

const houseSlice = createSlice({
	extraReducers: (builder) => {
		builder.addCase(createHouseThunk.pending, createHousePending)
		builder.addCase(createHouseThunk.rejected, createHouseRejected)
		builder.addCase(createHouseThunk.fulfilled, createHouseFulfelled)

		builder.addCase(updateHouseThunk.pending, updateHousePending)
		builder.addCase(updateHouseThunk.rejected, updateHouseRejected)
		builder.addCase(updateHouseThunk.fulfilled, updateHouseFulfilled)

		builder.addCase(getOneHouseThunk.pending, getOneHousePending)
		builder.addCase(getOneHouseThunk.rejected, getOneHouseRejected)
		builder.addCase(getOneHouseThunk.fulfilled, getOneHouseFulfilled)

		builder.addCase(getHousesThunk.pending, getHousesPending)
		builder.addCase(getHousesThunk.rejected, getHousesRejected)
		builder.addCase(getHousesThunk.fulfilled, getHousesFulfilled)

		builder.addCase(uploadPhotoThunk.pending, uploadPhotoPending)
		builder.addCase(uploadPhotoThunk.rejected, uploadPhotoRejected)
		builder.addCase(uploadPhotoThunk.fulfilled, uploadPhotoFulfilled)

		builder.addCase(removePhotoThunk.fulfilled, removePhotoFulfilled)
	},
	initialState,
	name: "houseSlice",
	reducers: {
		resetCreateHouseSuccess: (state) => ({ ...state, createHouseIsSuccess: false }),
		resetUpdateHouseSuccess: (state) => ({ ...state, updateHouseIsSuccess: false }),
		setFacilityId: (state, { payload }) => {
			if (state.facilityId !== payload) {
				state.facilityId = payload
				state.houses = []
			}
		},
		setHousesPage: (state, { payload: page }: PayloadAction<number>) => {
			state.page = page
			state.selectedHouse = undefined
		},
		setSelectedHouse: (state, { payload }) => {
			if (payload === state.selectedHouse?.id) {
				state.selectedHouse = undefined
				return
			}
			if (payload) {
				const house = state.houses.find(({ id }) => id === payload)
				if (house) {
					state.selectedHouse = house
					return
				}
			}
			state.selectedHouse = undefined
		},
	},
})

export default houseSlice

export const { resetCreateHouseSuccess, resetUpdateHouseSuccess, setFacilityId, setHousesPage, setSelectedHouse } = houseSlice.actions