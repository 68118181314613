import { createAsyncThunk } from "@reduxjs/toolkit"
import { api } from "@app/api"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { AppDispatch, RootState } from "src/store"
import { resetBuildings } from "src/slices/building-slice"

export const removeCheckerboardThunk = createAsyncThunk<void, number | undefined, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/remove",
	async (id, { dispatch, getState }) => {
		try {
			const { checkerboardSlice } = getState()
			const checkerboardId = id || checkerboardSlice.checkerboard?.id
			if (!checkerboardId) {
				dispatch(alertComponentShow({ severity: "error", text: "Шахматка не определена" }))
			}
			const text = await api.building.delete({ id })
			dispatch(resetBuildings())
            // dispatch(resetSections()) добавить, когда будут готовы секции;
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function removeCheckerboardPending(state: RootState["checkerboardSlice"]) {
	state.removeCheckerboardInProcess = true
}

export function removeCheckerboardRejected(state: RootState["checkerboardSlice"]) {
	state.removeCheckerboardInProcess = false
}

export function removeCheckerboardFulfilled(state: RootState["checkerboardSlice"]) {
	state.removeCheckerboardInProcess = false
}
