import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialState } from "./initial-state"
import { createCheckerboardFulfilled, createCheckerboardPending, createCheckerboardRejected, createCheckerboardThunk } from "./thunks/create-checkerboard-thunk";
import { getCheckerboardFulfilled, getCheckerboardPending, getCheckerboardRejected, getCheckerboardThunk } from "./thunks/get-checkerboard-thunk";
import { updateCheckerboardFulfilled, updateCheckerboardPending, updateCheckerboardRejected, updateCheckerboardThunk } from "./thunks/update-checkerboard-thunk";
import { removeCheckerboardFulfilled, removeCheckerboardPending, removeCheckerboardRejected, removeCheckerboardThunk } from "./thunks/remove-checkerboard-thunk";
import { CheckerboardMainInfoTab } from "@app/types/facilityTypes";
export { createCheckerboardThunk } from "./thunks/create-checkerboard-thunk"
export { getCheckerboardThunk } from "./thunks/get-checkerboard-thunk"
export { updateCheckerboardThunk } from "./thunks/update-checkerboard-thunk"
export { removeCheckerboardThunk } from "./thunks/remove-checkerboard-thunk"

const checkerboardSlice = createSlice({
	initialState,
	name: "checkerboardSlice",
	reducers: {
		resetCheckerboard: () => initialState,
		setCheckerboardMainInfoTab(state, { payload }: PayloadAction<CheckerboardMainInfoTab>) {
			state.mainInfoActiveTab = payload
		},
	},
	extraReducers: (build) => {
		build.addCase(createCheckerboardThunk.pending, createCheckerboardPending)
		build.addCase(createCheckerboardThunk.rejected, createCheckerboardRejected)
		build.addCase(createCheckerboardThunk.fulfilled, createCheckerboardFulfilled)

		build.addCase(getCheckerboardThunk.pending, getCheckerboardPending)
		build.addCase(getCheckerboardThunk.rejected, getCheckerboardRejected)
		build.addCase(getCheckerboardThunk.fulfilled, getCheckerboardFulfilled)

		build.addCase(updateCheckerboardThunk.pending, updateCheckerboardPending)
		build.addCase(updateCheckerboardThunk.rejected, updateCheckerboardRejected)
		build.addCase(updateCheckerboardThunk.fulfilled, updateCheckerboardFulfilled)

		build.addCase(removeCheckerboardThunk.pending, removeCheckerboardPending)
		build.addCase(removeCheckerboardThunk.rejected, removeCheckerboardRejected)
		build.addCase(removeCheckerboardThunk.fulfilled, removeCheckerboardFulfilled)
	},
})

export default checkerboardSlice
export const { resetCheckerboard, setCheckerboardMainInfoTab } = checkerboardSlice.actions