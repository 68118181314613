import {Announcement, CreateAnnouncementDto, UpdateAnnouncementDto} from "@app/types/announcement";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {api} from "@app/api";
import {FacilityNews} from "@app/types/facility";

type InitialState = {
    loading: boolean
    list?: Announcement[]
    single?: Announcement
    news?: NewsWithFacility
}

interface NewsWithFacility extends FacilityNews {
    facilityId: number
    facilityName?: string
}

const initialState: InitialState = {
    loading: true
}

const slice = createSlice({
    name: 'announcements',
    initialState: initialState,
    reducers: {
        loadAnnouncements(state: InitialState, action: PayloadAction<Announcement[]>): void {
            state.loading = false
            state.list = action.payload
        },
        loadAnnouncement(state: InitialState, action: PayloadAction<Announcement>): void {
            state.loading = false
            state.single = action.payload
        },
        clearAnnouncement(state: InitialState): void {
            state.single = undefined
        },
        createAnnouncement(state: InitialState, action: PayloadAction<Announcement>): void {
            state.loading = false
            if (state.list) {
                state.list.push(action.payload)
            } else {
                state.list = [action.payload]
            }
        },
        updateAnnouncement(state: InitialState, action: PayloadAction<Announcement>): void {
            const announcement = action.payload
            state.loading = false
            state.list = state.list.map((a, i) => {
                if (a.id !== announcement.id) return a
                else return announcement
            })
        },
        loadNews(state: InitialState, action: PayloadAction<NewsWithFacility>): void {
            state.news = action.payload
        }
    }
})

export const {reducer} = slice

export const {clearAnnouncement, loadNews} = slice.actions

export const loadAnnouncements = (): AppThunk => async (dispatch) => {
    const data = await api.announcement.getAll()
    dispatch(slice.actions.loadAnnouncements(data))
}

export const loadAnnouncement = (id: number): AppThunk => async (dispatch) => {
    const data = await api.announcement.getById(id)
    dispatch(slice.actions.loadAnnouncement(data))
}

export const createAnnouncement = (dto: CreateAnnouncementDto): AppThunk => async (dispatch) => {
    const data = await api.announcement.create(dto)
    console.log(data)
    dispatch(slice.actions.createAnnouncement(data))
}

export const updateAnnouncement = (id: number, dto: UpdateAnnouncementDto): AppThunk => async (dispatch) => {
    const data = await api.announcement.update(id, dto)
    dispatch(slice.actions.updateAnnouncement(data))
}
