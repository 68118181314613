import { CityType } from "@app/types/geoGuidTypes"
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getCityThunk = createAsyncThunk<CityType, number>("@@cities/get-city", async (cityId) => {
	return await api.city.getById(cityId)
})

export function getCityThunkPending(state: RootState['geoGuidCitiesSlice']) {
    state.getCityIsLoading = true
}

export function getCityThunkRejected(state: RootState["geoGuidCitiesSlice"]) {
	state.getCityIsLoading = false
}

export function getCityThunkFullfilled(state: RootState["geoGuidCitiesSlice"], { payload }: PayloadAction<CityType>) {
	state.getCityIsLoading = false
	state.city = payload
}