import { Apartment, ApartmentCell } from "@app/types/facilityTypes"
import AppApi from "."
import { apartment } from "./endpoints"

export default class ApartmentApi {
	constructor(private api: AppApi) {}

	public getForGrid(sectionId: number) {
		return this.api.client.get<void, ApartmentCell[]>(apartment.getForGrid(sectionId))
	}
}