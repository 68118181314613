import { getAllCountriesThunk } from './getAllCountriesThunk';
import { getCountryThunk } from './getCountryThunk';
import { CountryType } from '@app/types/geoGuidTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';

export const renameCountryThunk = createAsyncThunk<void, CountryType, { dispatch: AppDispatch }>("@@countries/rename", async (dto, { dispatch }) => {
	const countryId = dto.id
	await api.country.rename(dto)
	dispatch(getAllCountriesThunk())
	dispatch(getCountryThunk(countryId))
})

export function renameCountryThunkPending(state: RootState['geoGuidCountriesSlice']) {
    state.renameCountryIsLoading = true
}

export function renameCountryThunkRejected(state: RootState["geoGuidCountriesSlice"]) {
	state.renameCountryIsLoading = false
}

export function renameCountryThunkFullfilled(state: RootState["geoGuidCountriesSlice"]) {
	state.renameCountryIsLoading = false
	state.showRenameCountryModal = false
}