export type { FloorState } from "./state"

export enum FloorType {
	Residential = "Жилой",
	Mansarda = "Мансарда",
	Commercial = "Коммерческий",
	Parking = "Парковка",
	Minus = "Минус",
	Ground = "Цоколь",
}

type Floor = {
	id: number
	isDuplex: boolean
	layout_img: string | null
	name: string | null
	section_id: number
	sort_order: number | null
	type: FloorType
}

export type AddFloor = { floorsCount: number, section_id: number, type: FloorType }

export type UpdateFloor = {
	id: number
	isDuplex: boolean
	layout_img: string | null
	type: FloorType
}

export type AvailableFloorProps = {
	availableTypes: FloorType[]
	duplexChangeProbably: boolean
}

export interface IFloor extends Floor {}
