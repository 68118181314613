import AppApi from "@app/api/index";
import {owners} from "@app/api/endpoints";
import {Pagination} from "@app/types/pagination";
import {BanOwnerDto, CreateOwnerDto, GetOwnersParams, Owner, UpdateOwnerDto} from "@app/types/owner";

export default class OwnerApi {
    constructor(private readonly api: AppApi) {}

    public getAll(params: GetOwnersParams): Promise<Pagination<Owner[]>> {
        return this.api.client.get<null, Pagination<Owner[]>>(owners.getAll(), { params: params })
    }

    public getById(id: number): Promise<Owner> {
        return this.api.client.get<null, Owner>(owners.getById(id))
    }

    public create(dto: CreateOwnerDto): Promise<Owner> {
        return this.api.client.post<CreateOwnerDto, Owner>(owners.create(), dto)
    }

    public remove(id: number): Promise<boolean> {
        return this.api.client.delete<null, boolean>(owners.delete(id))
    }

    public update(id: number, dto: UpdateOwnerDto): Promise<Owner> {
        return this.api.client.patch<UpdateOwnerDto, Owner>(owners.update(id), dto)
    }

    public ban(dto: BanOwnerDto): Promise<null> {
        return this.api.client.post<BanOwnerDto, null>(owners.ban(), dto)
    }
}
