import { PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addRiserThunk = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/update-checkerboard",
	async (_, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, risers: stateRisers, section: stateSection } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection) {
			return
		}

		// добавление нового стояка к массиву имеющихся;
		const position = Math.max(...stateRisers.map(({ position }) => position), 0) + 1
		const risers = [...stateRisers, { position }]

		// замена секции с добавленным стояком в массиве секций строения;
		const section = { ...stateSection, risers }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с добавленным стояком в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function addRiserPending(state: PlateboardStateType) {
	state.addRiserIsLoading = true
}

export function addRiserFulfilled(state: PlateboardStateType) {
	state.addRiserIsLoading = false
	state.showRiserDialog = false
}

export function addRiserRejected(state: PlateboardStateType) {
	state.addRiserIsLoading = false
}