export const building = {
	create: () => "/building",
	getByCheckerboard: (checkerboardId: number) => `/building/by-checkerboard/${checkerboardId}`,
	update: () => "/building",
	delete: (buildingId: number) => `/building/${buildingId}`,
}

export const checkerboard = {
	add: () => "/checkerboard/add",
	create: () => "/checkerboard",
	getInfo: (id: number) => `/checkerboard/info/${id}`,
	update: () => "/checkerboard",
	delete: (id: number) => `/checkerboard/${id}`,

	upd: (id: number) => `/checkerboard/${id}`,
	getById: (id: number) => `/checkerboard/${id}`,
	createApartment: (checkerboardId: number) => `/checkerboard/${checkerboardId}/apartment`,
	updateApartment: (checkerboardId: number, apartmentId: number) => `/checkerboard/${checkerboardId}/apartment/${apartmentId}`,
	setApartmentLayoutPath: () => `/checkerboard/apartment/set-layout-path`,
	setFloorLayoutPath: () => `/checkerboard/floor/set-layout-path`,
	deleteApartment: (checkerboardId: number, apartmentId: number) => `/checkerboard/${checkerboardId}/apartment/${apartmentId}`,
	createGroup: (checkerboardId: number) => `/checkerboard/${checkerboardId}/group`,
	deleteGroup: (checkerboardId: number, groupId: number) => `/checkerboard/${checkerboardId}/group/${groupId}`,
	getFilters: (checkerboardId: number) => `/checkerboard/${checkerboardId}/filters`,
}

export const owners = {
	create: () => "/owners",
	getAll: () => "/v2/owners",
	getById: (id: number) => `/owners/${id}`,
	update: (id: number) => `/owners/${id}`,
	delete: (id: number) => `/owners/${id}`,
	ban: () => "/owners/ban",
}

export const developer = {
	create: () => "/developers",
	getAll: () => "/v2/developers",
	getById: (id: number) => `/developers/${id}`,
	update: (id: number) => `/developers/${id}`,
	delete: (id: number) => `/developers/${id}`,
	ban: () => "/developers/ban",
}

export const filters = {
	getAll: () => "/filters",
}

export const announcement = {
	getAll: () => "/announcements",
	create: () => "/announcements",
	getById: (id: number) => `/announcements/${id}`,
	update: (id: number) => `/announcements/${id}`,
	remove: (id: number) => `/announcements/${id}`,
}

export const contact = {
	send: () => "/contact/contactUs",
}

export const countries = {
	create: () => `/countries`,
	getAll: () => `/countries`,
	getById: (countryId: number) => `/countries/${countryId}`,
	rename: (countryId: number) => `/countries/${countryId}`,
	delete: (countryId: number) => `/countries/${countryId}`,
}

export const cities = {
	create: () => `/cities`,
	getAll: (countryId: number) => `/cities/${countryId}`,
	getById: (cityId: number) => `/cities/city/${cityId}`,
	getBySubstring: (substring: string) => `/cities/get-by-substring/${substring}`,
	rename: () => `/cities`,
	changeCoords: () => `/cities/change-coords`,
	delete: (cityId: number) => `/cities/${cityId}`,
}

export const areas = {
	create: () => `/areas`,
	getAll: (cityId: number) => `/areas/${cityId}`,
	getById: (areaId: number) => `/areas/area/${areaId}`,
	rename: () => `/areas`,
	delete: (areaId: number) => `/areas/${areaId}`,
}

export const houses = {
	create: () => `/houses`,
	getAll: (facilityId: number) => `/houses/${facilityId}`,
	getById: (houseId: number) => `/houses/house/${houseId}`,
	update: () => `/houses`,
	delete: (houseId: number) => `/houses/${houseId}`,
}

export const section = {
	create: () => "/section",
	getSectionList: (checkerboardId: number) => `/section/list/${checkerboardId}`,
	update: () => "/section",
	delete: (sectionId: number) => `/section/${sectionId}`,
}

export const floor = {
	addMany: () => "/floor/many",
	getAvailableProps: (floorId: number) => `/floor/available-props/${floorId}`,
	getBySectionId: (sectionId: number) => `/floor/by-section-id/${sectionId}`,
	update: () => "/floor",
	delete: (floorId: number) => `/floor/${floorId}`,
}

export const riser = {
	create: () => "/riser",
	getBySectionId: (sectionId: number) => `/riser/by-section-id/${sectionId}`,
	update: () => "/riser",
	delete: (riserId: number) => `/riser/${riserId}`,
}

export const apartment = {
	getForGrid: (sectionId: number) => `/apartment/grid/${sectionId}`,
}
