import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { api } from "@app/api"
import { Checkerboard } from "@app/types/facilityTypes"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { AppDispatch, RootState } from "src/store"

export const getCheckerboardThunk = createAsyncThunk<Checkerboard, number | undefined, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/get-info",
	async (id, { dispatch, getState }) => {
		try {
			const { checkerboardSlice } = getState()
			const checkerboardId = id || checkerboardSlice.checkerboard?.id
			if (!checkerboardId) {
				dispatch(alertComponentShow({ severity: "error", text: "Шахматка не определена" }))
			}
			return await api.checkerboard.getInfo(checkerboardId)
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function getCheckerboardPending(state: RootState["checkerboardSlice"]) {
	state.checkerboardIsLoading = !state.checkerboard
	state.checkerboardIsFetching = true
}

export function getCheckerboardRejected(state: RootState["checkerboardSlice"]) {
	state.checkerboardIsFetching = false
	state.checkerboardIsLoading = false
}

export function getCheckerboardFulfilled(state: RootState["checkerboardSlice"], { payload }: PayloadAction<Checkerboard>) {
	state.checkerboard = payload
	state.checkerboardIsFetching = false
	state.checkerboardIsLoading = false
}
