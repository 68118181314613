import AppApi from "./index";
import { AddSection, RemoveSection, SectionListItem, UpdateSection } from "@app/types/facilityTypes";
import { section } from "./endpoints";

export default class SectionApi {
	constructor(private api: AppApi) {}

	public create(request: AddSection) {
		return this.api.client.post<AddSection, string>(section.create(), request)
	}

	public getSectionList({ checkerboardId }: { checkerboardId: number }) {
		return this.api.client.get<void, SectionListItem[]>(section.getSectionList(checkerboardId))
	}

	public update(request: UpdateSection) {
		return this.api.client.put<UpdateSection, string>(section.update(), request)
	}

	public delete({ id }: RemoveSection) {
		return this.api.client.delete<void, string>(section.delete(id))
	}
}