import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state"
import { addManyFloorsFulfilled, addManyFloorsPending, addManyFloorsRejected, addManyFloorsThunk } from "./thunks/add-many-floors-thunk";
import { getAvailablePropsFulfilled, getAvailablePropsPending, getAvailablePropsRejected, getAvailablePropsThunk } from "./thunks/get-available-props-thunk"
import { getFloorsFulfilled, getFloorsPending, getFloorsRejected, getFloorsThunk } from "./thunks/get-floors-thunk"
import { removeFloorFulfilled, removeFloorPending, removeFloorRejected, removeFloorThunk } from "./thunks/remove-floor-thunk"
import { selectFloorFulfilled, selectFloorThunk } from "./thunks/select-floor-thunk"
import { updateFloorFulfilled, updateFloorPending, updateFloorRejected, updateFloorThunk } from "./thunks/update-floor-thunk"

export const floorSlice = createSlice({
    initialState,
    name: "floorSlice",
    reducers: {
        resetFloors: () => initialState,
        resetSelectedFloor(state) {
            state.availableProps = undefined
            state.selectedId = undefined
        },
    },
    extraReducers: build => {
        build.addCase(addManyFloorsThunk.fulfilled, addManyFloorsFulfilled)
        build.addCase(addManyFloorsThunk.pending, addManyFloorsPending)
        build.addCase(addManyFloorsThunk.rejected, addManyFloorsRejected)

        build.addCase(getFloorsThunk.fulfilled, getFloorsFulfilled)
		build.addCase(getFloorsThunk.pending, getFloorsPending)
		build.addCase(getFloorsThunk.rejected, getFloorsRejected)

        build.addCase(getAvailablePropsThunk.fulfilled, getAvailablePropsFulfilled)
		build.addCase(getAvailablePropsThunk.pending, getAvailablePropsPending)
		build.addCase(getAvailablePropsThunk.rejected, getAvailablePropsRejected)

        build.addCase(removeFloorThunk.fulfilled, removeFloorFulfilled)
		build.addCase(removeFloorThunk.pending, removeFloorPending)
		build.addCase(removeFloorThunk.rejected, removeFloorRejected)

        build.addCase(updateFloorThunk.fulfilled, updateFloorFulfilled)
		build.addCase(updateFloorThunk.pending, updateFloorPending)
		build.addCase(updateFloorThunk.rejected, updateFloorRejected)

        build.addCase(selectFloorThunk.fulfilled, selectFloorFulfilled)
    }
})

export default floorSlice

export { addManyFloorsThunk } from "./thunks/add-many-floors-thunk"
export { getAvailablePropsThunk } from "./thunks/get-available-props-thunk"
export { getFloorsThunk } from "./thunks/get-floors-thunk"
export { removeFloorThunk } from "./thunks/remove-floor-thunk"
export { selectFloorThunk } from "./thunks/select-floor-thunk"
export { updateFloorThunk } from "./thunks/update-floor-thunk"

export const { resetFloors, resetSelectedFloor } = floorSlice.actions