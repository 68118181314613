import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { getApartmentGridThunk } from "src/slices/apartment-slice";
import { getFloorsThunk } from "src/slices/floor-slice";
import { getRisersBySectionThunk } from "src/slices/riser-slice/thunks/get-risers-by-section-thunk";
import { AppDispatch, RootState } from "src/store"

export const selectSectionThunk = createAsyncThunk<number | undefined, number | undefined, { dispatch: AppDispatch; state: RootState }>(
    "@@section/select",
    async (data, { dispatch, getState }) => {
        const { sectionSlice } = getState()
        const { selectedSectionId } = sectionSlice
        if ( data && selectedSectionId !== data ) {
            dispatch(getApartmentGridThunk(data))
            dispatch(getFloorsThunk(data))
            dispatch(getRisersBySectionThunk(data))
        }
        return data
    }
)

export function selectSectionFulfilled(state: RootState["sectionSlice"], { payload }: PayloadAction<number | undefined>) {
    state.selectedSectionId = payload || state.selectedSectionId
}