import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { AddFloor } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";
import { getFloorsThunk } from "./get-floors-thunk";

export const addManyFloorsThunk = createAsyncThunk<void, Omit<AddFloor, "section_id">, { dispatch: AppDispatch; state: RootState }>(
	"@@floor/add-many",
	async (data, { dispatch, getState }) => {
		const { sectionSlice } = getState()
		const section_id = sectionSlice.selectedSectionId
		if (!section_id) {
			dispatch(alertComponentShow({ severity: "error", text: "Секция не определена" }))
		}
		try {
			const text = await api.floor.addMany({ ...data, section_id })
            dispatch(alertComponentShow({ severity: "success", text }))
            dispatch(getFloorsThunk(undefined))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function addManyFloorsPending(state: RootState["floorSlice"]) {
    state.createInProcess = true
}

export function addManyFloorsRejected(state: RootState["floorSlice"]) {
    state.createInProcess = false
}

export function addManyFloorsFulfilled(state:RootState["floorSlice"]) {
    state.createInProcess = false
}