import { addManyRisersFulfilled, addManyRisersPending, addManyRisersRejected, addManyRisersThunk } from './thunks/addManyRisersThunk';
import { addManyFloorsFulfilled, addManyFloorsPending, addManyFloorsRejected, addManyFloorsThunk } from './thunks/addManyFloorsThunk';
import { updateFloorFulfilled, updateFloorPending, updateFloorRejected, updateFloorThunk } from './thunks/updateFloorThunk';
import { deleteRiserFulfilled, deleteRiserPending, deleteRiserRejected, deleteRiserThunk } from 'src/slices/plateboard/thunks/deleteRiserThunk';
import { addRiserFulfilled, addRiserPending, addRiserRejected, addRiserThunk } from 'src/slices/plateboard/thunks/addRiserThunk';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CheckerboardTabs, PlateboardCellType, PlateboardStateType } from "@app/types/checkerboard";
import { initialState } from "./initialState"
import { getPlateboardThunk, getPlateboardFulfilled, getPlateboardPending, getPlateboardRejected } from './thunks/getPlateboardThunk';
import { createApartmentFulfilled, createApartmentPending, createApartmentRejected, createApartmentThunk } from "./thunks/createApartmentThunk"
import { createGroupFulfilled, createGroupPending, createGroupRejected, createGroupThunk } from "./thunks/createGroupThunk"
import { updateApartmentFulfilled, updateApartmentPending, updateApartmentRejected, updateApartmentThunk } from "./thunks/updateApartmentThunk";
import { deleteApartmentFulfilled, deleteApartmentPending, deleteApartmentRejected, deleteApartmentThunk } from "./thunks/deleteApartmentThunk";
import { deleteGroupFulfilled, deleteGroupPending, deleteGroupRejected, deleteGroupThunk } from "./thunks/deleteGroupThunk";
import { setSectionCells } from './helpers/getSectionCells'
import { toggleSelectCell } from "./helpers/toggleSelectCell";
import { deleteFloorFulfilled, deleteFloorPending, deleteFloorRejected, deleteFloorThunk } from './thunks/deleteFloorThunk';
import { setFloorLayoutThunk, setFloorLayoutThunkFulfilled, setFloorLayoutThunkPending, setFloorLayoutThunkRejected } from './thunks/setFloorLayoutThunk';

const plateboardSlice = createSlice({
	name: "plateboard",
	initialState: initialState,
	reducers: {
		resetCheckerboard: () => initialState,
		selectCell(state, { payload }: PayloadAction<PlateboardCellType>) {
			toggleSelectCell(state, payload)
		},
		selectSecondCell(state, { payload }: PayloadAction<PlateboardCellType>) {
			const index = Math.min(state.selectedCells.length, 1)
			state.selectedCells[index] = payload
		},
		setInteractive(state, { payload }: PayloadAction<boolean>) {
			state.interactive = payload
		},
		setSelectedFloor(state, { payload }: PayloadAction<PlateboardStateType["selectedFloor"]>) {
			state.selectedFloor = payload
			state.showFloorDialog = !!payload
		},
		setSelectedRiser(state, { payload }: PayloadAction<PlateboardStateType["selectedRiser"]>) {
			state.selectedRiser = payload
			state.showRiserDialog = !!payload
		},
		setShowRFDialog(state, { payload }: PayloadAction<boolean>) {
			state.showRFDialog = payload
		},
		setActiveTab(state, { payload }: PayloadAction<CheckerboardTabs>) {
			state.activeTab = payload
		},
		setShowEditForm(state, { payload }: PayloadAction<boolean>) {
			state.showEditForm = payload
		},
		setBuilding(state, { payload }: PayloadAction<PlateboardStateType["building"] | undefined>) {
			state.building = payload
		},
		setSection(state, { payload }: PayloadAction<PlateboardStateType["section"]>) {
			setSectionCells(state, payload)
			state.selectedCells = []
			state.selectedFloor = undefined
			state.selectedRiser = undefined
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getPlateboardThunk.pending, getPlateboardPending)
			.addCase(getPlateboardThunk.fulfilled, getPlateboardFulfilled)
			.addCase(getPlateboardThunk.rejected, getPlateboardRejected)

			.addCase(createApartmentThunk.pending, createApartmentPending)
			.addCase(createApartmentThunk.fulfilled, createApartmentFulfilled)
			.addCase(createApartmentThunk.rejected, createApartmentRejected)

			.addCase(updateApartmentThunk.pending, updateApartmentPending)
			.addCase(updateApartmentThunk.fulfilled, updateApartmentFulfilled)
			.addCase(updateApartmentThunk.rejected, updateApartmentRejected)

			.addCase(deleteApartmentThunk.pending, deleteApartmentPending)
			.addCase(deleteApartmentThunk.fulfilled, deleteApartmentFulfilled)
			.addCase(deleteApartmentThunk.rejected, deleteApartmentRejected)

			.addCase(createGroupThunk.pending, createGroupPending)
			.addCase(createGroupThunk.fulfilled, createGroupFulfilled)
			.addCase(createGroupThunk.rejected, createGroupRejected)

			.addCase(deleteGroupThunk.pending, deleteGroupPending)
			.addCase(deleteGroupThunk.fulfilled, deleteGroupFulfilled)
			.addCase(deleteGroupThunk.rejected, deleteGroupRejected)

			.addCase(addRiserThunk.pending, addRiserPending)
			.addCase(addRiserThunk.fulfilled, addRiserFulfilled)
			.addCase(addRiserThunk.rejected, addRiserRejected)

			.addCase(deleteRiserThunk.pending, deleteRiserPending)
			.addCase(deleteRiserThunk.fulfilled, deleteRiserFulfilled)
			.addCase(deleteRiserThunk.rejected, deleteRiserRejected)

			.addCase(setFloorLayoutThunk.pending, setFloorLayoutThunkPending)
			.addCase(setFloorLayoutThunk.fulfilled, setFloorLayoutThunkFulfilled)
			.addCase(setFloorLayoutThunk.rejected, setFloorLayoutThunkRejected)

			.addCase(updateFloorThunk.pending, updateFloorPending)
			.addCase(updateFloorThunk.fulfilled, updateFloorFulfilled)
			.addCase(updateFloorThunk.rejected, updateFloorRejected)

			.addCase(deleteFloorThunk.pending, deleteFloorPending)
			.addCase(deleteFloorThunk.fulfilled, deleteFloorFulfilled)
			.addCase(deleteFloorThunk.rejected, deleteFloorRejected)

			.addCase(addManyFloorsThunk.pending, addManyFloorsPending)
			.addCase(addManyFloorsThunk.fulfilled, addManyFloorsFulfilled)
			.addCase(addManyFloorsThunk.rejected, addManyFloorsRejected)

			.addCase(addManyRisersThunk.pending, addManyRisersPending)
			.addCase(addManyRisersThunk.fulfilled, addManyRisersFulfilled)
			.addCase(addManyRisersThunk.rejected, addManyRisersRejected)
	},
})

export default plateboardSlice
export const {
	resetCheckerboard,
	selectCell,
	setActiveTab,
	setBuilding,
	setInteractive,
	setSection,
	setSelectedFloor,
	selectSecondCell,
	setSelectedRiser,
	setShowRFDialog,
	setShowEditForm,
} = plateboardSlice.actions