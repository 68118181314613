import { HouseState } from "@app/types/houseTypes";

export const initialState: HouseState = {
	createHouseIsLoading: false,
	createHouseIsSuccess: false,
	houses: [],
	housesIsLoading: false,
	houseIsLoading: false,
	page: 0,
	pageSize: 50,
	renamePhotoIsLoading: false,
	updateHouseIsLoading: false,
	updateHouseIsSuccess: false,
	uploadPhotoIsLoading: false,
}