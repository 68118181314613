import { Floor, FloorType, PlateboardStateType } from '@app/types/checkerboard';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { api } from '@app/api';
import { AppDispatch, RootState } from "src/store"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { range } from 'lodash';

function getMansardaFloors(floors: Floor[], addedFloorsType: FloorType) {
	const result = floors.filter(({ type }) => type === FloorType.Mansarda)

	if (addedFloorsType === FloorType.Mansarda && !result.length) {
		result.push({ name: "M", sort_order: 0, type: FloorType.Mansarda, isDuplex: false })
	}

	return result
}

function getResidentalFloors(floors: Floor[], addedFloorsType: FloorType, addedFloorsCount = 1) {
	const result = [...floors].reverse().filter((value) => value.type === FloorType.Residential)

	if (addedFloorsType === FloorType.Residential) {
		const floorsNamesNumber = result.map(({ name }) => (name))
			.filter(item => !isNaN(+item))
			.filter(item => !(+item === 0 && item !== '0'))
			.map(item => +item)

		const startFloorNumber = Math.max(0, ...floorsNamesNumber) + 1
		const endFloorNumber = startFloorNumber + addedFloorsCount

		const floors = range(startFloorNumber, endFloorNumber).map(item => ({
			name: item.toString(),
			sort_order: 0,
			type: FloorType.Residential,
			isDuplex: false	
		}))

		result.push(...floors)
	}

	return result
}

function getCommercicalFloors(floors: Floor[], addedFloorsType: FloorType) {
	const result = floors.filter(({ type }) => type === FloorType.Commercial)

	if (addedFloorsType === FloorType.Commercial) {
		result.push({ name: "K", sort_order: 0, type: FloorType.Commercial, isDuplex: false })
	}

	return result
}

function getMinusFloors(floors: Floor[], addedFloorsType: FloorType) {
	const result = floors.filter(({ type }) => type === FloorType.Minus)

	if (addedFloorsType === FloorType.Minus) {
		const floorsNamesNumber = result.map(({ name }) => (name))
			.filter(item => !isNaN(+item))
			.filter(item => !(+item === 0 && item !== '0'))
			.map(item => +item)
		const minFloor = Math.min(0, ...floorsNamesNumber) - 1 

		result.unshift({ name: minFloor.toString(), sort_order: 0, type: FloorType.Minus, isDuplex: false })
	}

	return result
}

function getGroundFloors(floors: Floor[], addedFloorsType: FloorType) {
	const result = floors.filter(({ type }) => type === FloorType.Ground)

	if (addedFloorsType === FloorType.Ground) {
		result.unshift({ name: "Ц", sort_order: 0, type: FloorType.Ground, isDuplex: false })
	}

	return result
}

function getParkingFloors(floors: Floor[], addedFloorsType: FloorType) {
	const result = floors.filter(({ type }) => type === FloorType.Parking)

	if (addedFloorsType === FloorType.Parking) {
		const sort_order = Math.max(0, ...result.map(({ sort_order }) => sort_order)) + 1
		result.unshift({
			name: "П",
			sort_order,
			type: FloorType.Parking,
			isDuplex: false,
		})
	}

	return result
}

export const addManyFloorsThunk = createAsyncThunk<void, { addedFloorsCount: number, addedFloorsType: FloorType }, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/add-many-floors",
	async ({ addedFloorsCount, addedFloorsType }, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const { building: stateBuilding, checkerboard, floors: stateFloors, section: stateSection } = state.plateboard
		if (!stateBuilding || !checkerboard || !stateSection) {
			return
		}

		const mansardaFloors = getMansardaFloors(stateFloors, addedFloorsType)
		const residentialFloors = getResidentalFloors(stateFloors, addedFloorsType, addedFloorsCount)
		const commercialFloors = getCommercicalFloors(stateFloors, addedFloorsType)
		const minusFloors = getMinusFloors(stateFloors, addedFloorsType)
		const groundFloors = getGroundFloors(stateFloors, addedFloorsType)
		const parkingFloors = getParkingFloors(stateFloors, addedFloorsType)

		const floors = [...parkingFloors, ...groundFloors, ...minusFloors, ...commercialFloors, ...residentialFloors, ...mansardaFloors]
			.map((item, sort_order) => ({ ...item, sort_order }))

		// замена секции с новыми этажами в массиве секций строения;
		const section = { ...stateSection, floors }
		const sections = stateBuilding.sections.map((item) => (item.id === section.id ? section : item))

		// замена строения с удалёленным стояком в массиве строений;
		const building = { ...stateBuilding, sections }
		const buildings = checkerboard.buildings.map((item) => (item.id === building.id ? building : item))
		const dto = { ...checkerboard, buildings }

		const checkerboardId = checkerboard.id
		await api.checkerboard.updateCheckerboard(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
	}
)

export function addManyFloorsPending(state: PlateboardStateType) {
	state.addManyFloorsIsLoading = true
}

export function addManyFloorsFulfilled(state: PlateboardStateType) {
	state.addManyFloorsIsLoading = false
	state.showRFDialog = false
}

export function addManyFloorsRejected(state: PlateboardStateType) {
	state.addManyFloorsIsLoading = false
}