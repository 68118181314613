import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { IFloor, UpdateFloor } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";

export const updateFloorThunk = createAsyncThunk<void, Omit<UpdateFloor, "id">, { dispatch: AppDispatch, state: RootState }>(
    "@@floor/update",
    async (data, { dispatch, getState }) => {
        const { floorSlice } = getState()
        const id = floorSlice.selectedId
        if (!id) {
			dispatch(alertComponentShow({ severity: "error", text: "Этаж не определен" }))
		}
        try {
            const text = await api.floor.update({ ...data, id })
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
        
    }
)

export function updateFloorPending(state: RootState["floorSlice"]) {
    state.updateInProcess = true
}

export function updateFloorRejected(state: RootState["floorSlice"]) {
    state.updateInProcess = false
}

export function updateFloorFulfilled(state:RootState["floorSlice"]) {
    state.updateInProcess = false
}