import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const deleteAreaThunk = createAsyncThunk<void, number>("@@areas/delete-area", async (cityId) => {
	await api.area.delete(cityId)
})

export function deleteAreaThunkPending(state: RootState["geoGuidAreasSlice"]) {
	state.deleteAreaIsLoading = true
}

export function deleteAreaThunkRejected(state: RootState["geoGuidAreasSlice"]) {
	state.deleteAreaIsLoading = false
}

export function deleteAreaThunkFullfilled(state: RootState["geoGuidAreasSlice"]) {
	state.deleteAreaIsLoading = false
	state.deleteAreaIsSuccess = true
	state.showDeleteAreaModal = false
}