import { AreaType } from "@app/types/geoGuidTypes"
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { RootState } from 'src/store';

export const getAreaThunk = createAsyncThunk<AreaType, number>("@@areas/get-area", async (areaId) => {
	return await api.area.getById(areaId)
})

export function getAreaThunkPending(state: RootState['geoGuidAreasSlice']) {
    state.getAreaIsLoading = true
}

export function getAreaThunkRejected(state: RootState["geoGuidAreasSlice"]) {
	state.getAreaIsLoading = false
}

export function getAreaThunkFullfilled(state: RootState["geoGuidAreasSlice"], { payload }: PayloadAction<AreaType>) {
	state.getAreaIsLoading = false
	state.area = payload
}