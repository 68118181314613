import { getAllCitiesThunk } from "./getAllCitiesThunk"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';
import { CreateCityType } from "@app/types/geoGuidTypes";

export const createCityThunk = createAsyncThunk<void, Omit<CreateCityType, 'countryId'>, { dispatch: AppDispatch, state: RootState }>(
	"@@cities/create",
	async (data, { dispatch, getState }) => {
		const state = getState()
		const countryId = state.geoGuidCountriesSlice.country?.id
		if ( !countryId ) {
			throw new Error('Страна для добавления города не определена')
		}
		await api.city.createCity({ ...data, countryId })
		dispatch(getAllCitiesThunk())
	}
)

export function createCityThunkPending(state: RootState['geoGuidCitiesSlice']) {
    state.createCityIsLoading = true
}

export function createCityThunkRejected(state: RootState['geoGuidCitiesSlice']) {
	state.createCityIsLoading = false
}

export function createCityThunkFullfilled(state: RootState['geoGuidCitiesSlice']) {
	state.createCityIsLoading = false
	state.showAddCityModal = false
}