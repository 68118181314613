import { alertComponentShow } from './../../alertComponentSlice';
import { getHousesThunk } from './getHousesThunk';
import { api } from '@app/api';
import { CreateHouse } from '@app/types/houseTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';

export const createHouseThunk = createAsyncThunk<void, Omit<CreateHouse, 'facilityId'>, { dispatch: AppDispatch; state: RootState }>(
	"@@houses/create",
	async (data, { dispatch, getState }) => {
		const state = getState()
		const facilityId = state.facilitySlice.facility?.id
        const faclitiyIsLoading = state.facilitySlice.facilityIsLoading
		if (!facilityId || faclitiyIsLoading) {
			throw new Error("Коттеджный посёлок не определён")
		}
		try {
			const text = await api.house.create({ ...data, facilityId })
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch(e: any) {
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: 'error', text }))
			throw e
		}
		dispatch(getHousesThunk())
	}
)

export function createHousePending(state: RootState['houseSlice']) {
    state.createHouseIsLoading = true
}

export function createHouseRejected(state: RootState["houseSlice"]) {
	state.createHouseIsLoading = false
}

export function createHouseFulfelled(state: RootState["houseSlice"]) {
	state.createHouseIsLoading = false
	state.createHouseIsSuccess = true
}
