import { setShowEditForm } from 'src/slices/plateboard/plateboardSlice';
import { getPlateboardThunk } from './getPlateboardThunk';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { CreateApartmentDto, PlateboardStateType } from "@app/types/checkerboard"
import { api } from "@app/api"
import { AppDispatch, RootState } from "src/store"

export const createApartmentThunk = createAsyncThunk<void, CreateApartmentDto, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/create-apartment",
	async (createApartmentDto, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const checkerboardId = state.plateboard.checkerboard?.id
		const sectionId = state.plateboard.section?.id
		const selectedCell = state.plateboard.selectedCells[0]
		if (!selectedCell || !sectionId) {
			return
		}
		const floorId = +selectedCell.floorId
		const riserId = +selectedCell.riserId
		const dto = { apartment: createApartmentDto, floorId, riserId, sectionId }
		await api.checkerboard.createApartment(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
		dispatch(setShowEditForm(false))
	}
)

export function createApartmentPending(state: PlateboardStateType) {
	state.createApartmentIsLoading = true
}

export function createApartmentFulfilled(state: PlateboardStateType) {
	state.createApartmentIsLoading = false
}

export function createApartmentRejected(state: PlateboardStateType) {
	state.createApartmentIsLoading = false
}