import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { AddSection } from "@app/types/facilityTypes"
import { api } from "@app/api";
import { getBuildingsByCheckerboardThunk } from "src/slices/building-slice/thunks/get-buildings-by-checkerboard-thunk"
import { alertComponentShow } from "src/slices/alertComponentSlice"

export const createSectionThunk = createAsyncThunk<void, AddSection, { dispatch: AppDispatch; state: RootState }>(
    "@@section/create",
    async (data, { dispatch }) => {
        try {
            const text = await api.section.create(data)
            dispatch(getBuildingsByCheckerboardThunk(undefined))
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function createSectionThunkPending(state: RootState['sectionSlice']) {
    state.createSectionInProcess = true
}

export function createSectionThunkRejected(state: RootState["sectionSlice"]) {
	state.createSectionInProcess = false
}

export function createSectionThunkFulfilled(state: RootState["sectionSlice"]) {
	state.createSectionInProcess = false
    state.createSuccess = true
}