import { PlateboardCellType, PlateboardStateType, Section } from '@app/types/checkerboard';
import { getEmptyCells } from './getEmptyCells';
import { getApartmentGroupCellPosition } from './gridCellPosition';
import { groupingApartmentLinks } from './groupingApartmentLinks';

export function setSectionCells(state: PlateboardStateType, section?: Section) {
	state.section = section
	if (section) {
		const usedCells = {};
		const floors = [...section.floors].sort((a, b) => a.sort_order - b.sort_order).reverse();
		const risers = [...section.risers].sort((a, b) => a.position - b.position);
		const groups = groupingApartmentLinks(section.apartmentLinks);
		const newGroups = [];

		groups.forEach((item) => {
			if (section.id === item[0].sectionId) {
				if (!usedCells[`${item[0].sectionId}_${item[0].riserId}_${item[0].floorId}`]) {
					newGroups.push(item);
					usedCells[`${item[0].sectionId}_${item[0].riserId}_${item[0].floorId}`] = true;
				}
			}
		});

		const apartmentCells = newGroups.map<PlateboardCellType>((item) => {
			const cellPosition = getApartmentGroupCellPosition(item, floors, risers);
			const id = `${section.id}_${cellPosition.left}_${cellPosition.top}`;

			return {
				apartment: item[0]?.apartment,
				cellPosition,
				id,
				floorId: item[0]?.floorId,
				riserId: item[0]?.riserId,
			}
		});

		const emptyCells = getEmptyCells(floors, risers, apartmentCells, section.id.toString())
		const cells = [...apartmentCells, ...emptyCells]
		state.cells = cells
		state.floors = floors
		state.risers = risers
	} else {
		state.cells = []
		state.floors = []
		state.risers = []
	}
}