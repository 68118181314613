import { api } from '@app/api';
import { IHouse } from '@app/types/houseTypes';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { initHouseFilter } from 'src/slices/houseFilterSlice';
import { setFacilityId } from "../houseSlice"
import { AppDispatch, RootState } from 'src/store';

export const getHousesThunk = createAsyncThunk<IHouse[], number | void, { dispatch: AppDispatch, state: RootState }>(
    '@@houses/fetch-all',
    async (id, { dispatch, getState }) => {
        const state = getState()
        if (id) {
			dispatch(setFacilityId(id))
            const houses = await api.house.getAll(id)
            dispatch(initHouseFilter(houses))
            return houses
		}
        const { facilityId } = state.houseSlice
        if ( facilityId ) {
            const houses = await api.house.getAll(facilityId)
            dispatch(initHouseFilter(houses))
            return houses
        } else {
            throw new Error('КП не определён')
        }
    }
)

export function getHousesPending(state: RootState['houseSlice']) {
    state.housesIsLoading = !state.houses.length
}

export function getHousesRejected(state: RootState["houseSlice"]) {
	state.housesIsLoading = false
}

export function getHousesFulfilled(state: RootState['houseSlice'], { payload }: PayloadAction<IHouse[]>) {
    state.housesIsLoading = false
    state.houses = payload
    state.selectedHouse = undefined
}
