import { ApartmentState } from "@app/types/facilityTypes";

export const initialState: ApartmentState = {
    cells: [],
    createInProcess: false,
    isFetching: false,
    isLoading: false,
    removeInProcess: false,
    selectedEmptyCells: [],
    updateInProcess: false,
}