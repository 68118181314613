import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@app/api";
import { IFloor } from "@app/types/facilityTypes";
import { alertComponentShow } from "src/slices/alertComponentSlice";
import { AppDispatch, RootState } from "src/store";

export const getFloorsThunk = createAsyncThunk<IFloor[], number | undefined, { dispatch: AppDispatch, state: RootState }>(
    "@@floor/get-by-section",
    async (id, { dispatch, getState }) => {
        const { sectionSlice } = getState()
        const sectionId = id || sectionSlice.selectedSectionId
        if ( !sectionId ) {
            dispatch(alertComponentShow({ severity: "error", text: "Секция не определена" }))
        }
        try {
            return await api.floor.getBySectionId(sectionId)
        } catch (e) {
            console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
        
    }
)

export function getFloorsPending(state: RootState["floorSlice"]) {
    state.isFetching = true
    state.isLoading = !state.floors.length
}

export function getFloorsRejected(state: RootState["floorSlice"]) {
    state.isFetching = false
	state.isLoading = false
}

export function getFloorsFulfilled(state:RootState["floorSlice"], { payload }: PayloadAction<IFloor[]>) {
    state.isFetching = false
	state.isLoading = false
    state.floors = payload
}