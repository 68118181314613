import { getCityThunk } from './getCityThunk';
import { ChangeCoordsCityType } from "@app/types/geoGuidTypes"
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';

export const changeCoordsCityThunk = createAsyncThunk<void, ChangeCoordsCityType, { dispatch: AppDispatch }>("@@cities/change-coords", async (dto, { dispatch }) => {
	await api.city.changeCoords(dto)
	dispatch(getCityThunk(dto.id))
})

export function changeCoordsCityThunkPending(state: RootState['geoGuidCitiesSlice']) {
    state.changeCoordsCityIsLoading = true
}

export function changeCoordsCityThunkRejected(state: RootState["geoGuidCitiesSlice"]) {
	state.changeCoordsCityIsLoading = false
}

export function changeCoordsCityThunkFullfilled(state: RootState["geoGuidCitiesSlice"]) {
	state.changeCoordsCityIsLoading = false
	state.showChangeCoordsCityModal = false
}