import { FacilityRoomCount } from "@app/types/houseTypes"

type topLineIndex = number
type rightLineIndex = number
type bottomLineIndex = number
type leftLineIndex = number

export type { ApartmentState } from "./state"

export type Cell = {
    parentApartmentId?: number
	coords: [topLineIndex, rightLineIndex, bottomLineIndex, leftLineIndex]
	floorIds: number[]
	riserIds: number[]
}

export enum ApartmentDecoration {
	Prefinishing = "Предчистовая",
	Finishing = "Чистовая",
	WhiteBox = "White Box",
	Draft = "Черновая",
	Repair = "Ремонт",
}

export type ApartmentBase = {
	decoration: ApartmentDecoration
	id: number
	price: number
	number: string
	roomCount: FacilityRoomCount
	square: number
	squarePrice: number
}

export type ApartmentInfo = {
	commissions: string[]
	decoration: ApartmentDecoration
	floorLayout?: string
	floor: string
	layout?: string
	riser: string
	roomCount: FacilityRoomCount
	sales: string[]
}

export type ApartmentProps = {
	hasCession: boolean
	hasSales: boolean
	isBooked: boolean
	isEnabled: boolean
	isDuplex: boolean
	isHidden: boolean
	isReserved: boolean
	isSold: boolean
}

export type AddApartmentByLink = {
	decoration?: ApartmentDecoration
	parentApartmentId: number
	price?: number | string
	number: string
	roomCount?: FacilityRoomCount
	sectionId: number
	square?: number | string
	squarePrice?: number | string
}

export type AddApartment = {
	decoration?: ApartmentDecoration
	floorId: number
	price?: string | number
	number: string
	riserId: number
	roomCount?: FacilityRoomCount
	sectionId: number
	square?: string | number
	squarePrice?: string | number
}

export type UpdateApartment = {
	decoration: ApartmentDecoration
	id: number
	price: string | number
	number: string
	roomCount: FacilityRoomCount
	square: string | number
	squarePrice: string | number
}

export type UpdateApartmentComissionsAndSales = {
	commissions: string[]
	id: number
	sales: string[]
}

export type UpdateApartmentLayout = {
    id: number
    layout: string | null
}

export type UpdateApartmentFloorLayout = {
	floorLayoutOutline: string
	floorLayoutPath: string
	id: number
}

export type UpdateApartmentProps = { id: number } & ApartmentProps

export type Apartment = ApartmentBase & ApartmentProps

export type ApartmentWithInfo = Apartment & ApartmentInfo

export type ApartmentCell = {
	apartment?: Apartment
	cell: Cell
}