import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { ApartmentCell } from "@app/types/facilityTypes"
import { AppDispatch, RootState } from "src/store"
import { api } from "@app/api"
import { alertComponentShow } from "src/slices/alertComponentSlice"

export const getApartmentGridThunk = createAsyncThunk<ApartmentCell[], number | undefined, { dispatch: AppDispatch, state: RootState }>(
    "@@apartment/get-grid",
    async (data, { dispatch, getState }) => {
        const { sectionSlice } = getState()
        const id = data || sectionSlice.selectedSectionId
        try {
            return await api.apartment.getForGrid(id)
        } catch (e) {
            console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
        }
    }
)

export function getApartmentGridPending(state: RootState["apartmentSlice"]) {
    state.isFetching = true
    state.isLoading = !state.cells.length
}

export function getApartmentGridRejected(state: RootState["apartmentSlice"]) {
    state.isFetching = false
    state.isLoading = false
}

export function getApartmentGridFulfilled(state: RootState["apartmentSlice"], { payload }: PayloadAction<ApartmentCell[]>) {
    state.cells = payload
    state.isFetching = false
	state.isLoading = false
}