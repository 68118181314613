import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@app/api';
import { AppDispatch, RootState } from 'src/store';
import { alertComponentShow } from "src/slices/alertComponentSlice"

export const deleteCityThunk = createAsyncThunk<void, number, { dispatch: AppDispatch }>("@@cities/delete-city", async (cityId, { dispatch }) => {
	try {
		const text = await api.city.delete(cityId)
		dispatch(alertComponentShow({ severity: "success", text }))
	} catch (e) {
		console.warn(e)
		const text = e?.response?.data?.error_message || e?.message || "Ошибка удаления города..."
		dispatch(alertComponentShow({ severity: "error", text }))
		throw e
	}
	
})

export function deleteCityThunkPending(state: RootState["geoGuidCitiesSlice"]) {
	state.deleteCityIsLoading = true
}

export function deleteCityThunkRejected(state: RootState["geoGuidCitiesSlice"]) {
	state.deleteCityIsLoading = false
}

export function deleteCityThunkFullfilled(state: RootState["geoGuidCitiesSlice"]) {
	state.deleteCityIsLoading = false
	state.deleteCityIsSuccess = true
	state.showDeleteCityModal = false
}