import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { api } from "@app/api";
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { getRisersBySectionThunk } from "./get-risers-by-section-thunk";

export const createRiserThunk = createAsyncThunk<void, number | undefined, { dispatch: AppDispatch; state: RootState }>(
    "@@riser/create",
    async (id, { dispatch, getState }) => {
        try {
            const { sectionSlice } = getState()
            const sectionId = id || sectionSlice.selectedSectionId
            if ( !sectionId ) {
                const text = "Ошибка добавления стояка"
				dispatch(alertComponentShow({ severity: "error", text }))
                throw new Error("Секция не определена")
            }
            const text = await api.riser.create({ sectionId })
            dispatch(getRisersBySectionThunk(undefined))
            dispatch(alertComponentShow({ severity: "success", text }))
        } catch (e) {
            console.log(e)
            const text = e?.response?.data?.error_message || "Что-то пошло не так..."
            dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
    }
)

export function createRiserThunkPending(state: RootState['riserSlice']) {
    state.createRiserInProcess = true
}

export function createRiserThunkRejected(state: RootState["riserSlice"]) {
	state.createRiserInProcess = false
}

export function createRiserThunkFulfilled(state: RootState["riserSlice"]) {
	state.createRiserInProcess = false
}