import { getAllAreasThunk } from './getAllAreasThunk';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAreaThunk } from "./getAreaThunk"
import { AreaType } from "@app/types/geoGuidTypes"
import { api } from "@app/api"
import { AppDispatch, RootState } from "src/store"

export const renameAreaThunk = createAsyncThunk<void, AreaType, { dispatch: AppDispatch }>("@@areas/rename", async (dto, { dispatch }) => {
	await api.area.rename(dto)
	dispatch(getAllAreasThunk())
	dispatch(getAreaThunk(dto.id))
})

export function renameAreaThunkPending(state: RootState["geoGuidAreasSlice"]) {
	state.renameAreaIsLoading = true
}

export function renameAreaThunkRejected(state: RootState["geoGuidAreasSlice"]) {
	state.renameAreaIsLoading = false
}

export function renameAreaThunkFullfilled(state: RootState["geoGuidAreasSlice"]) {
	state.renameAreaIsLoading = false
	state.showRenameAreaModal = false
}
