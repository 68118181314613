import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { PlateboardStateType } from "@app/types/checkerboard"
import { api } from "@app/api"
import { AppDispatch, RootState } from "src/store"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { getPlateboardThunk } from "./getPlateboardThunk"

export const setFloorLayoutThunk = createAsyncThunk<void, File | null, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/update-floor-layout",
	async (file, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		try {
			const { checkerboard, selectedFloor } = state.plateboard
			if (!selectedFloor) {
				throw new Error("Этаж не определён")
			}

			const { id } = selectedFloor
			
			if ( file ) {
				const { filename } = await api.file.uploadImage(file)
				const text = await api.checkerboard.setFloorLayoutPath({ floorLayout: filename, id })
				dispatch(alertComponentShow({ severity: "success", text }))
			} else {
				const text = await api.checkerboard.setFloorLayoutPath({ floorLayout: null, id })
				dispatch(alertComponentShow({ severity: "success", text }))
			}
			
			if ( checkerboard?.id ) {
				dispatch(getPlateboardThunk({ checkerboardId: checkerboard.id }))
			}
		} catch (e) {
			console.warn(e)
            const text = e?.response?.data?.error_message || e?.message || "Ошибка загрузки изображения..."
			dispatch(alertComponentShow({ severity: "error", text }))
            throw e
        }
	}
)

export function setFloorLayoutThunkPending(state: PlateboardStateType) {
	state.updateFloorIsLoading = true
}

export function setFloorLayoutThunkFulfilled(state: PlateboardStateType) {
	state.updateFloorIsLoading = false
}

export function setFloorLayoutThunkRejected(state: PlateboardStateType) {
	state.updateFloorIsLoading = false
}
