import { setShowEditForm } from 'src/slices/plateboard/plateboardSlice';
import { getPlateboardThunk } from 'src/slices/plateboard/thunks/getPlateboardThunk';
import { createAsyncThunk } from "@reduxjs/toolkit"
import { PlateboardStateType, UpdateApartmentDto } from "@app/types/checkerboard"
import { api } from "@app/api"
import { AppDispatch, RootState } from "src/store"
import { FacilityFlatDecoration, RoomCount } from '@app/types/facility';

const mockApartment = {
	price: 0,
	roomCount: RoomCount.STUDIO,
	sales: [],
	hasCession: false,
	squarePrice: 0,
	square: 0,
	decoration: FacilityFlatDecoration.FINISHING,
	isEnabled: true,
	isBooked: false,
	isReserved: false,
	isHidden: false,
	number: "",
}

export const updateApartmentThunk = createAsyncThunk<void, UpdateApartmentDto, { dispatch: AppDispatch; state: RootState }>(
	"@@checkerboard/update-apartment",
	async (updFields, thunkApi) => {
		const { dispatch, getState } = thunkApi
		const state = getState()
		const checkerboardId = state.plateboard.checkerboard?.id
		const selectedCell = state.plateboard.selectedCells?.[0]
		const sectionId = state.plateboard.section?.id
		const apartmentId = selectedCell?.apartment?.id
		const isBooked = selectedCell?.apartment?.isBooked

		if ( apartmentId ) {
			await api.checkerboard.updateApartment(checkerboardId, apartmentId, { isBooked: isBooked, ...updFields })
			dispatch(getPlateboardThunk({ checkerboardId }))
			dispatch(setShowEditForm(false))
			return
		}

		if ( !selectedCell || !sectionId ) {
			return
		}

		const { floorId, riserId } = selectedCell

		if ( isNaN(+floorId) || isNaN(+riserId) ) {
			return
		}
		
		const apartment = { ...mockApartment, ...updFields }
		const dto = { apartment, floorId: +floorId, riserId: +riserId, sectionId, isBooked: isBooked }
		await api.checkerboard.createApartment(checkerboardId, dto)
		dispatch(getPlateboardThunk({ checkerboardId }))
		dispatch(setShowEditForm(false))
	}
)

export function updateApartmentPending(state: PlateboardStateType) {
	state.updateApartmentIsLoading = true
}

export function updateApartmentFulfilled(state: PlateboardStateType) {
	state.updateApartmentIsLoading = false
}

export function updateApartmentRejected(state: PlateboardStateType) {
	state.updateApartmentIsLoading = false
}