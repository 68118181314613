import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "src/store"
import { api } from "@app/api"
import { alertComponentShow } from "src/slices/alertComponentSlice"
import { getRisersBySectionThunk } from "./get-risers-by-section-thunk"

export const removeRiserThunk = createAsyncThunk<void, number | undefined, { dispatch: AppDispatch; state: RootState }>(
	"@@riser/remove",
	async (riserId, { dispatch, getState }) => {
		try {
			const { riserSlice } = getState()
			const id = riserId || riserSlice.selectedRiserId
			if ( !id ) {
				const text = "Ошибка удаления стояка"
				dispatch(alertComponentShow({ severity: "success", text }))
				throw new Error(text)
			}
			const text = await api.riser.delete({ id })
			dispatch(getRisersBySectionThunk(undefined))
			dispatch(alertComponentShow({ severity: "success", text }))
		} catch (e) {
			console.log(e)
			const text = e?.response?.data?.error_message || "Что-то пошло не так..."
			dispatch(alertComponentShow({ severity: "error", text }))
			throw e
		}
	}
)

export function removeRiserThunkPending(state: RootState["riserSlice"]) {
	state.removeRiserInProcess = true
}

export function removeRiserThunkRejected(state: RootState["riserSlice"]) {
	state.removeRiserInProcess = false
}

export function removeRiserThunkFulfilled(state: RootState["riserSlice"]) {
	state.removeRiserInProcess = false
}
