import {DeliveryDate, Filter, FilterParams, filterResponseToParams, GeoFilterType} from "@app/types/filter";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {api} from "@app/api";
import {
    FacilityBenefit,
    FacilityCategory,
    FacilityFlatDecoration,
    FacilityHouseClass,
    FacilityPaymentOption,
    FacilityRegistration,
    FacilityType
} from "@app/types/facility";

type PayloadArrayKey = {
    key: string
    data: string[]
}

type PayloadRangeKey = {
    key: string
    data: number[]
}

type StateFilters = {
	all: Filter
	filtered: Filter & GeoFilterType
	category?: FacilityCategory
}

type InitialState = {
    loading: boolean
    value?: StateFilters
}

const initialState: InitialState = {
    loading: false
}

const emptyFilters: Filter & GeoFilterType = {
	areasIds: [],
	countries: [],
	districts: [],
	deliveryDates: [],
	benefits: [],
	decorations: [],
	registrations: [],
	facilityTypes: [],
	houseClasses: [],
	paymentOptions: [],
	microDistricts: [],
}

const slice = createSlice({
	name: "filters",
	initialState: initialState,
	reducers: {
		reset(state: InitialState): void {
			state.value = undefined
		},
		clear(state: InitialState): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered = emptyFilters
		},
		initFilters(state: InitialState, action: PayloadAction<Filter>): void {
			state.value = {
				all: action.payload,
				filtered: emptyFilters,
			}
		},
		loadFilters(state: InitialState, action: PayloadAction<Filter>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value = {
				...state.value,
				filtered: action.payload,
			}
		},
		setCategory(state: InitialState, action: PayloadAction<FacilityCategory>) {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			if (state.value.category === action.payload) {
				state.value.category = undefined
			} else {
				state.value.category = action.payload
			}
		},
		setAreasIds(state, { payload }: PayloadAction<number[]>) {
            if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.areasIds = payload
        },
		setCityId(state, { payload }: PayloadAction<number | undefined>) {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.cityId = payload
			if (!payload) {
				state.value.filtered.areasIds = []
			}
		},
		setCountryId(state, { payload }: PayloadAction<number | undefined>) {
            if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.countryId = payload
			if (!payload) {
				state.value.filtered.cityId = undefined
				state.value.filtered.areasIds = []
			}
        },
		addDistricts(state: InitialState, action: PayloadAction<string[]>): void {
			const districts = action.payload
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.districts.push(...new Set([...districts, ...state.value.filtered.districts]))
		},
		setDistricts(state: InitialState, action: PayloadAction<string[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.districts = action.payload
		},
		setMicroDistricts(state: InitialState, action: PayloadAction<string[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.microDistricts = action.payload
		},
		removeMicroDistrict(state: InitialState, action: PayloadAction<string>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.microDistricts = state.value.filtered.microDistricts.filter((x) => x !== action.payload)
		},
		setBenefits(state: InitialState, action: PayloadAction<FacilityBenefit[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.benefits = action.payload
		},
		removeBenefit(state: InitialState, action: PayloadAction<FacilityBenefit>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.benefits = state.value.filtered.benefits.filter((x) => x !== action.payload)
		},
		setDecorations(state: InitialState, action: PayloadAction<FacilityFlatDecoration[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.decorations = action.payload
		},
		removeDecoration(state: InitialState, action: PayloadAction<FacilityFlatDecoration>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.decorations = state.value.filtered.decorations.filter((x) => x !== action.payload)
		},
		setPaymentOptions(state: InitialState, action: PayloadAction<FacilityPaymentOption[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.paymentOptions = action.payload
		},
		removePaymentOption(state: InitialState, action: PayloadAction<FacilityPaymentOption>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.paymentOptions = state.value.filtered.paymentOptions.filter((x) => x !== action.payload)
		},
		setRegistrations(state: InitialState, action: PayloadAction<FacilityRegistration[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.registrations = action.payload
		},
		removeRegistration(state: InitialState, action: PayloadAction<FacilityRegistration>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.registrations = state.value.filtered.registrations.filter((x) => x !== action.payload)
		},
		setHouseClasses(state: InitialState, action: PayloadAction<FacilityHouseClass[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.houseClasses = action.payload
		},
		removeHouseClass(state: InitialState, action: PayloadAction<FacilityHouseClass>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.houseClasses = state.value.filtered.houseClasses.filter((x) => x !== action.payload)
		},
		setFacilityTypes(state: InitialState, action: PayloadAction<FacilityType[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.facilityTypes = action.payload
		},
		removeFacilityType(state: InitialState, action: PayloadAction<FacilityType>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.facilityTypes = state.value.filtered.facilityTypes.filter((x) => x !== action.payload)
		},
		removeDistricts(state: InitialState, action: PayloadAction<string[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const districts = action.payload

			districts.forEach((d) => {
				if (state.value?.filtered.districts.includes(d)) {
					state.value.filtered.districts = state.value.filtered.districts.filter((x) => x !== d)
				}
			})
		},
		setSquare(state: InitialState, action: PayloadAction<number[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const data = action.payload

			if (data.length === 0) {
				state.value.filtered.square = emptyFilters.square
			} else {
				const [min, max] = data
				if (state.value.all.square?.min !== min || state.value.all.square.max !== max) {
					state.value.filtered.square = { min, max }
				}
			}
		},
		setPrice(state: InitialState, action: PayloadAction<number[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const data = action.payload

			if (data.length === 0) {
				state.value.filtered.price = emptyFilters.price
			} else {
				const [min, max] = data
				if (state.value.all.price?.min !== min || state.value.all.price.max !== max) {
					state.value.filtered.price = { min, max }
				}
			}
		},
		setDistanceToTheBeach(state: InitialState, action: PayloadAction<number[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const data = action.payload

			if (data.length === 0) {
				state.value.filtered.distanceToTheBeach = emptyFilters.distanceToTheBeach
			} else {
				const [min, max] = data
				if (state.value.all.distanceToTheBeach?.min !== min || state.value.all.distanceToTheBeach.max !== max) {
					state.value.filtered.distanceToTheBeach = { min, max }
				}
			}
		},
		setPricePerMeter(state: InitialState, action: PayloadAction<number[]>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const data = action.payload

			if (data.length === 0) {
				state.value.filtered.pricePerMeter = emptyFilters.pricePerMeter
			} else {
				const [min, max] = data
				if (state.value.all.pricePerMeter?.min !== min || state.value.all.pricePerMeter.max !== max) {
					state.value.filtered.pricePerMeter = { min, max }
				}
			}
		},
		setDeliveryDates(state: InitialState, action: PayloadAction<DeliveryDate[]>) {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			state.value.filtered.deliveryDates = action.payload
		},
		removeDeliveryDate(state: InitialState, action: PayloadAction<DeliveryDate>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const { year, quarter } = action.payload
			state.value.filtered.deliveryDates = state.value.filtered.deliveryDates.filter((x) => !(x.year === year && x.quarter === quarter))
		},
		clearByKey(state: InitialState, action: PayloadAction<string>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const key = action.payload
			state.value.filtered[key] = emptyFilters[key]
		},
		setArrayFilter(state: InitialState, action: PayloadAction<PayloadArrayKey>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const { key, data } = action.payload
			state.value.filtered[key] = data
		},
		setRangeFilter(state: InitialState, action: PayloadAction<PayloadRangeKey>): void {
			if (!state.value) {
				state.value = { all: emptyFilters, filtered: emptyFilters }
			}
			const { key, data } = action.payload
			if (data.length === 0) {
				state.value.filtered[key] = emptyFilters[key]
			} else {
				const [min, max] = data
				if (state.value.all[key]["min"] !== min || state.value.all[key]["max"] !== max) {
					state.value.filtered[key] = { min, max }
				}
			}
		},
	},
})

export const {reducer} = slice

export const {
    removeDistricts,
    reset,
    addDistricts,
    clear, clearByKey,
    setDistricts,
    setSquare,
    setArrayFilter, setRangeFilter,
    setDeliveryDates,
    setFacilityTypes,
    setBenefits,
    setDecorations,
    setDistanceToTheBeach,
    setHouseClasses,
    setMicroDistricts,
    setPaymentOptions,
    setPrice,
    setPricePerMeter,
    setRegistrations,
    removeBenefit,
    removeMicroDistrict,
    removeDecoration,
    removeDeliveryDate,
    removeFacilityType,
    removeHouseClass,
    removePaymentOption,
    removeRegistration,
    setCategory,
    setCityId,
    setCountryId,
    setAreasIds,
} = slice.actions

export const initFilters = (): AppThunk => async (dispatch) => {
    const params: FilterParams = {}
    const data = await api.filters.getAll(params)

    dispatch(slice.actions.initFilters(data))
}

export const loadFilters = (filters: Filter): AppThunk => async (dispatch) => {
    const params = filterResponseToParams(filters)
    const data = await api.filters.getAll(params)

    dispatch(slice.actions.loadFilters(data))
}
