import AppApi from "./index";
import { IRiser, UpdateRiser } from "@app/types/facilityTypes";
import { riser } from "./endpoints";

export default class RiserApi {
	constructor(private api: AppApi) {}

	public create(request: { sectionId: number }) {
		return this.api.client.post<{ sectionId: number }, string>(riser.create(), request)
	}

	public getBySectionId({ sectionId }: { sectionId: number }) {
		return this.api.client.get<void, IRiser[]>(riser.getBySectionId(sectionId))
	}

	public update(request: UpdateRiser) {
		return this.api.client.put<UpdateRiser, string>(riser.update(), request)
	}

	public delete({ id }: { id: number }) {
		return this.api.client.delete<void, string>(riser.delete(id))
	}
}