import { checkerboard } from "@app/api/endpoints";
import AppApi from "@app/api/index";
import {
    Checkerboard as Checker,
    CheckerboardFilters,
    CheckerboardFiltersParams,
    CreateApartmentLinkDto,
    CreateCheckerboardDto,
    CreateGroupDto,
    CreateGroupResponse,
    SetApartmentLayoutPathDto,
    SetFloorLayoutPathDto,
    UpdateApartmentDto,
    UpdateCheckerboardDto,
} from "@app/types/checkerboard";
import { AddCheckerboard, Checkerboard, UpdateCheckerboard } from "@app/types/facilityTypes"

export default class CheckerboardApi {
	constructor(private readonly api: AppApi) {}

	public add(data: AddCheckerboard) {
		return this.api.client.post<AddCheckerboard, number>(checkerboard.add(), data)
	}

	public getInfo(id: number): Promise<Checkerboard> {
		return this.api.client.get<null, Checkerboard>(checkerboard.getInfo(id))
	}

	public update(data: UpdateCheckerboard) {
		return this.api.client.put<UpdateCheckerboard, string>(checkerboard.update(), data)
	}

	public deleteCheckerboard(id: number) {
		return this.api.client.delete(checkerboard.delete(id))
	}

	public createCheckerboard(dto: CreateCheckerboardDto) {
		return this.api.client.post<CreateCheckerboardDto, Checker>(checkerboard.create(), dto)
	}

	public getCheckerboardById(id: number, params?: CheckerboardFiltersParams): Promise<Checker> {
		return this.api.client.get<null, Checker>(checkerboard.getById(id), { params: params })
	}

	public updateCheckerboard(id: number, dto: UpdateCheckerboardDto) {
		return this.api.client.put<UpdateCheckerboardDto, Checker>(checkerboard.upd(id), dto)
	}

	public setApartmentLayoutPath(dto: SetApartmentLayoutPathDto) {
		return this.api.client.put<SetApartmentLayoutPathDto, string>(checkerboard.setApartmentLayoutPath(), dto)
	}

	public setFloorLayoutPath(dto: SetFloorLayoutPathDto) {
		return this.api.client.put<SetFloorLayoutPathDto, string>(checkerboard.setFloorLayoutPath(), dto)
	}

	public createApartment(checkerboardId: number, dto: CreateApartmentLinkDto) {
		return this.api.client.post<CreateApartmentLinkDto, null>(checkerboard.createApartment(checkerboardId), dto)
	}

	public updateApartment(checkerboardId: number, apartmentId: number, dto: UpdateApartmentDto) {
		return this.api.client.put<UpdateApartmentDto, null>(checkerboard.updateApartment(checkerboardId, apartmentId), dto)
	}

	public deleteApartment(checkerboardId: number, apartmentId: number) {
		return this.api.client.delete(checkerboard.deleteApartment(checkerboardId, apartmentId))
	}

	public createGroup(checkerboardId: number, dto: CreateGroupDto) {
		return this.api.client.post<CreateGroupDto, CreateGroupResponse>(checkerboard.createGroup(checkerboardId), dto)
	}

	public deleteGroup(checkerboardId: number, groupId: number) {
		return this.api.client.delete<null>(checkerboard.deleteGroup(checkerboardId, groupId))
	}

	public getFilters(checkerboardId: number, params: CheckerboardFiltersParams): Promise<CheckerboardFilters> {
		return this.api.client.get<null, CheckerboardFilters>(checkerboard.getFilters(checkerboardId), { params: params })
	}
}
