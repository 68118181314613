import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Checkerboard, CheckerboardFiltersParams} from "@app/types/checkerboard";
import {api} from "@app/api";

type InitialState = {
    defaultCheckerboard?: Checkerboard
    filteredCheckerboard?: Checkerboard
    loading: boolean
}

export const getCheckerboardThunk = createAsyncThunk("@@checkerboard/get", (params: GetCheckerboardParams) => {
	return api.checkerboard.getCheckerboardById(params.id)
})

export const getCheckerboardWithFiltersThunk = createAsyncThunk("@@checkerboard/get-with-filters", (params: GetCheckerboardParams) => {
	return api.checkerboard.getCheckerboardById(params.id, params.filters)
})

const initialState: InitialState = {loading: false}

const slice = createSlice({
    name: 'checkerboard',
    initialState: initialState,
    reducers: {
        resetCheckerboard(state: InitialState) {
            state.defaultCheckerboard = undefined
            state.filteredCheckerboard = undefined
            state.loading = false
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCheckerboardThunk.pending, (state) => {
                state.loading = true
            })
            .addCase(getCheckerboardThunk.fulfilled, (state, action) => {
                state.loading = false
                state.defaultCheckerboard = action.payload
                state.filteredCheckerboard = action.payload
            })
            .addCase(getCheckerboardThunk.rejected, (state) => {
                state.loading = false
                state.defaultCheckerboard = undefined
            })
            .addCase(getCheckerboardWithFiltersThunk.pending, (state) => {
                state.loading = true
            })
            .addCase(getCheckerboardWithFiltersThunk.fulfilled, (state, action) => {
                state.loading = false
                state.filteredCheckerboard = action.payload
            })
    }
})

export const reducer = slice.reducer
export const {resetCheckerboard} = slice.actions

type GetCheckerboardParams = {
    id: number
    filters?: CheckerboardFiltersParams
}
